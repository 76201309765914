export const getSystemUrlByEnv = ({ url }) => {
    const urls = {
        "alpha-app.contelege.com.br": "alpha-app.contelege.com.br",
        "app.contelege.com.br": "app.contelege.com.br",
        "staging-app.contelege.com.br": "staging-app.contelege.com.br",
        "ge-staging-subscription.contele.com.br": "staging-app.contelege.com.br",
        "localhost": "staging-app.contelege.com.br",
    }
    return urls[url] || "app.contelege.com.br";
}
export const getApiUrlByEnv = ({ url }) => {
    const urls = {
        "alpha-app.contelege.com.br": "cge-prd-api.contele.io",
        "app.contelege.com.br": "cge-prd-api.contele.io",
        "staging-app.contelege.com.br": "cge-stg-api.contele.io",
        "localhost": "cge-stg-api.contele.io",
    }
    return urls[url] || "cge-prd-api.contele.io";
}

export const getSystemApiUrlByEnv = ({url})=>{
    const urls ={
        "alpha-app.contelege.com.br": "ge-prd-web-api.contele.com.br",
        "app.contelege.com.br":"ge-prd-web-api.contele.com.br",
        "staging-app.contelege.com.br":"ge-staging-web-api.contele.com.br",
        "localhost":"ge-staging-web-api.contele.com.br"
    }
    return urls[url] || "ge-staging-web-api.contele.com.br"
}

