import React, { useEffect, useState } from 'react';
import {
    QuestionButtonContainer, QuestionButton, QuestionTitleDiv, ModalQuestionStyle,
    QuestionDescription,
} from './style'
import { Text } from '../../../components'
import { localizedStrings } from '../../../constants/localizedStrings';
import { useSelector } from 'react-redux';
import { SYSTEM_URL } from '../../../constants/environment';
export default function ModalInfo({
    title, subtitle, hasImage = false, urlImage, onClose, description = localizedStrings.errorDescriptionGeneric, showErrors, redirectMessage,
    redirectButton
}) {
    const [descriptionError, setDescriptionError] = useState("")
    const withImageStyle = hasImage ? {
        display: "flex",
        alignItems: "flex-end"
    } : null;

    const {
        createErrors, updateErrors, limitExceeded,
    } = useSelector(state => state.subscription);

    const getErrorDescription = (errorsList) => {
        if (limitExceeded) return localizedStrings.errorLimitExceeded;
        if (!showErrors) return description;
        if (errorsList.length === 0) return description;

        let errorsNames = "";

        if (errorsList.length === 1) {
            errorsNames = errorsList.join(", ");
        } else {
            // reading parse
            errorsNames = errorsList.slice(0, errorsList.length - 1).join(", ") + " e " + errorsList[errorsList.length - 1];
        }

        return localizedStrings.errorDescription1 + errorsNames + localizedStrings.errorDescription2;
    }

    const styleRedirectSubtitle = redirectMessage ? {
        width: "370px",
        height: "84px",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "21px",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
    } : null;
    
    const styleRedirectMessage = redirectMessage ? {
        width: "345px",
        height: "38px",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "19px",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
    } : null;

    useEffect(() => {
        if (createErrors.length > 0) setDescriptionError(getErrorDescription(createErrors));
        if (updateErrors.length > 0) setDescriptionError(getErrorDescription(updateErrors));
        // eslint-disable-next-line
    }, [createErrors, updateErrors, limitExceeded])

    const redirect = (e) => {
        e.preventDefault();
        return window.open(`${SYSTEM_URL}/#/produtividade`, "_self")
    }

    return (
        <ModalQuestionStyle className={"row"} hasImage={hasImage} >
            <QuestionTitleDiv className={"col-md-12 col-sm-12 col-12"} >
                <Text flex={typeof onClose !== 'undefined' && "1"} color={"#767676"} textTransform={"uppercase"} fontFamily={"Effra light, Roboto,sans-serif"} fontSize={"16px"}>
                    {title}
                </Text>
                {typeof onClose !== 'undefined' &&
                    <div onClick={onClose} >
                        <Text color={"#767676"} cursor={"pointer"} fontFamily={"effra, Roboto,sans-serif"} fontSize={"20px"}>
                            x
                        </Text>
                    </div>
                }
            </QuestionTitleDiv>
            <QuestionDescription className={"col-md-12 col-sm-12 col-12"}  >
                <div>
                    {
                        hasImage &&
                        <img alt={localizedStrings.altImage} src={urlImage} />
                    }
                    <Text {...withImageStyle} style={styleRedirectSubtitle} fontFamily={"Effra light, Roboto,sans-serif"} flex={"1"} fontSize={"19px"}>
                        {subtitle}
                    </Text>
                    {
                        redirectMessage && 
                        <Text color={"#767676"} style={styleRedirectMessage}fontFamily={"Effra light, Roboto,sans-serif"} fontSize={"17px"}>
                            {redirectMessage}
                        </Text>
                    }
                    {
                        !hasImage &&
                        <Text color={"#767676"} fontFamily={"Effra light, Roboto,sans-serif"} fontSize={"17px"}>
                            {
                                descriptionError
                            }
                        </Text>
                    }
                </div>
            </QuestionDescription>
            <QuestionButtonContainer className={"col-md-12 col-sm-12 col-12"}  >
                <div>
                    {
                        redirectButton && 
                        <QuestionButton border={"1px solid #fff"} background={"#1a2565"} marginRight={"5px"} onClick={e => redirect(e)} >
                            <Text color={"#fff"} fontFamily={"Effra light, Roboto,sans-serif"} fontSize={"19px"}>
                                {localizedStrings.understand}
                            </Text>
                        </QuestionButton>
                    }
                    {
                       !redirectButton && <QuestionButton border={"1px solid #fff"} background={"#1a2565"} marginRight={"5px"} onClick={onClose} >
                            <Text color={"#fff"} fontFamily={"Effra light, Roboto,sans-serif"} fontSize={"19px"}>
                                {localizedStrings.close}
                            </Text>
                    </QuestionButton>}
                </div>
            </QuestionButtonContainer>
        </ModalQuestionStyle>
    );
}
