
import styled from 'styled-components';


const priceBlockStyle = styled.div(props => ({
   height:"177px"
}))
const priceStyle = styled.div(props => ({
}))


export {
    priceStyle as PriceStyle,
    priceBlockStyle as PriceBlockStyle,
}
