export const USER_LOGIN_SUCCESS = 'user_login_success'
export const USER_RESET = 'user_reset'
export const UPDATE_FETCH_STATE = 'update_fetch_state'
export const FETCH_ACTIVE_SUBSCRIPTION = 'fetch_active_subscription'
export const FETCH_RECEIPTS = 'fetch_receipts'
export const FETCH_RECEIPTS_SUCCESS = 'fetch_receipts_success';
export const LOCK_USER = 'lock_user'
export const GET_ACTIVE_LICENSES = 'get_active_licenses';

const INITIAL_STATE = {
    user: {},
    company: {},
    activeSubscription: {},
    receipts: [],
    loadingActiveSubscription: false,
    lock: false,
    response: false,
    activeLicenses: 0
};



export default function users(state = INITIAL_STATE, action) {
    const actionTypes = {
        user_login_success() {
            return {
                ...state,
                user: {
                    token: action.payload.user.token,
                },
                company: {
                    ...action.payload.user.company
                }
            }
        },
        user_reset() { return { ...state, user: {} } },
        update_fetch_state() {return { ...state, loadingActiveSubscription: action.payload.loadingActiveSubscription }},
        fetch_active_subscription() { return { ...state, activeSubscription: { ...action.payload.activeSubscription } } },
        fetch_receipts() {
            return {
                ...state,
                receipts: action.payload.receipts
            }
        },
        fetch_receipts_success() {
            return {
                ...state,
                response: action.payload.response || [],
            }
        },
        lock_user() {return {...state, lock: action.payload.lock}},
        get_active_licenses() { return {...state, activeLicenses: action.payload.activeLicenses}}

    }

    return actionTypes?.[action.type]?.() ?? state;
}