import React, { memo } from 'react';
import { StructureStyle, ContentWrapper, MainContent } from './style'
import { Header, Footer } from '../../components'
const width = window.innerWidth;
const isMobile = width < 860;
export default memo(function Container({ children, containerOptions, ...options }) {
    return (
        <StructureStyle {...containerOptions}>
            <div>
                <ContentWrapper margin={"0px"} width={isMobile ? "100vw" : "80vw"} className={"row"}>
                    <Header width={width} />
                    <MainContent {...options}>
                        {children}
                    </MainContent>
                </ContentWrapper>
                <Footer style={{ width: isMobile ? "100vw" : "80vw", margin: "0px" }} />
            </div>
        </StructureStyle>
    );
})