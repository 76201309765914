
import {
    USER_LOGIN_SUCCESS,
    USER_RESET,
    UPDATE_FETCH_STATE,
    FETCH_ACTIVE_SUBSCRIPTION,
    FETCH_RECEIPTS,
    LOCK_USER,
    FETCH_RECEIPTS_SUCCESS,
    GET_ACTIVE_LICENSES
} from './reducer';
import api from '../../../services/api';
import axios from 'axios';
import { changeCardPriceToBR, changeCardPriceToUS } from '../../modules';
import { LOGIN_URL, APP_PATH, SYSTEM_URL, AUTH_PATH, WEB_API_URL } from '../../../constants/environment';
import { getAccessTokenFromLocalStorage } from '../../../utils/getAccessToken';

const subscriptionFullUrl = SYSTEM_URL + AUTH_PATH;

export function resetTokenAndRedirect() {
    localStorage.removeItem("access_token");
    localStorage.setItem("redirect", subscriptionFullUrl);
    return window.open(LOGIN_URL, "_self")
}

export function fetchLoginSuccess(user) {
    return {
        type: USER_LOGIN_SUCCESS,
        payload: {
            user
        }
    };
}
export function updateFetchState({ loading = false }) {
    return {
        type: UPDATE_FETCH_STATE,
        payload: {
            loadingActiveSubscription: loading,
        }
    };
}
export function fetchActiveSubscription(activeSubscription) {
    return {
        type: FETCH_ACTIVE_SUBSCRIPTION,
        payload: {
            activeSubscription
        }
    };
}

export function fetchReceiptsSuccess(response) {
    return {
        type: FETCH_RECEIPTS_SUCCESS,
        payload: {
            response
        }
    };
}

export function fetchReceipts(receipts) {
    return {
        type: FETCH_RECEIPTS,
        payload: {
            receipts
        }
    };
}

export function logout() {
    return {
        type: USER_RESET
    };
}

export function lockUser(lock = false) {
    return {
        type: LOCK_USER,
        payload: {
            lock
        }
    };
}

export function getActiveLicenses(activeLicenses) {
    return {
        type: GET_ACTIVE_LICENSES,
        payload: {
            activeLicenses
        }
    };
}

export const getUser = data => async dispatch => {
    const { urlToken = false, lastToken = false, getUserSubscription = true, withRedirect = true } = data;
    const URL = "/billing/v1/client/ge";
    let successToken = false;

    const successAuth = (token, company) => dispatch(fetchLoginSuccess({ token, company }));

    if ((urlToken === lastToken) && (urlToken !== false && lastToken !== false)) successToken = urlToken

    if (!urlToken && lastToken) successToken = lastToken;

    if (urlToken) successToken = urlToken;

    if (!urlToken && !lastToken) successToken = false;

    if (successToken) {
        localStorage.setItem("access_token", JSON.stringify(successToken));
        
        const userData = successToken && JSON.parse(atob(successToken.split('.')[1]));

        if(userData.userType !== "admin") dispatch(lockUser(true));
        if(userData.userType === "admin") dispatch(lockUser());

        let company = {};
        await api.get(URL)
            .then(result => {
                if (result && result?.data) {
                    const { content: companyData } = result.data;
                    const [
                        countryIsNull,
                        countryIsBrazil
                    ] = [
                            companyData?.country === null || companyData?.country === undefined,
                            companyData?.country === "BR"
                        ]

                    company = {
                        clientId: companyData?.id,
                        status: companyData?.status,
                        licenses: companyData?.licenses,
                        corporateName: companyData?.corporateName,
                        identification: companyData?.identification,
                        phone: companyData?.phone,
                        email: companyData?.email_copy,
                        email_copy: companyData?.email_copy,
                        address: {
                            zipcode: companyData?.address?.zipcode,
                            name: companyData?.address?.name,
                            complement: companyData?.address?.complement,
                            number: companyData?.address?.number,
                            neighborhood: companyData?.address?.neighborhood,
                        },
                        country: (countryIsBrazil || countryIsNull) ? "BR" : companyData?.country,
                        currency: (countryIsBrazil || countryIsNull) ? "R$" : "U$",
                        consultant: companyData.consultant,
                    };

                    if (countryIsBrazil || countryIsNull) {
                        dispatch(changeCardPriceToBR())
                    } else {
                        dispatch(changeCardPriceToUS())
                    }

                    if (getUserSubscription) {
                        const subscriptionPayload = {
                            clientId: company?.clientId,
                            redirect: withRedirect,
                            status: company?.status,
                            url: APP_PATH + "/review"
                        }

                        dispatch(getSubscription(subscriptionPayload))
                    }

                    dispatch(_getActiveLicenses(company))

                    return successAuth(successToken, company);
                }
            })
            .catch(error => {
                if (error?.response?.status === 500) {
                    resetTokenAndRedirect()
                }
            });

    } else {
        resetTokenAndRedirect()
    }
};



export const getReceipts = data => async dispatch => {
    const { clientId } = data;
    const URL = "/billing/v1/ge/" + clientId + "/invoices/";
    api.get(URL)
        .then(result => {
            if (result && result?.data?.invoices && Array.isArray(result?.data?.invoices)) {
                dispatch(fetchReceipts(result.data.invoices?.reverse()));
            }
        })
        .catch(error => {
            dispatch(fetchReceipts([]));
            console.log(error);
        });
};



const redirectToUrl = (url) => {
    window.location.href = window.location.origin + url;
}

export const getSubscription = data => async dispatch => {
    const { clientId, redirect = false } = data;
    if (!clientId) {
        resetTokenAndRedirect();;
    }
    const URL = "/billing/v1/ge/" + clientId;
    dispatch(updateFetchState({ loading: true }));
    dispatch(fetchReceiptsSuccess(false));
    await api.get(URL)
        .then(result => {
            if (result?.data?.content) {
                const { content: activeSubscription } = result.data;
                dispatch(fetchActiveSubscription({
                    client_id: activeSubscription?.client_id,
                    product: activeSubscription?.product,
                    corporateName: activeSubscription?.corporateName,
                    email: activeSubscription?.email,
                    identification: activeSubscription?.identification,
                    type_subscription: activeSubscription?.type_subscription,
                    plan_subscription: activeSubscription?.plan_subscription,
                    phone: activeSubscription?.phone,
                    zipcode: activeSubscription?.zipcode,
                    address: activeSubscription?.address,
                    complement: activeSubscription?.complement,
                    number: activeSubscription?.number,
                    neighborhood: activeSubscription?.neighborhood,
                    city: activeSubscription?.city,
                    state: activeSubscription?.state,
                    country: activeSubscription?.country,
                    number_licenses: activeSubscription?.number_licenses,
                    price_per_license: activeSubscription?.price_per_license,
                    due_at: activeSubscription?.due_at,
                    holder_name: activeSubscription?.holder_name,
                    card_expiration: activeSubscription?.card_expiration,
                    card_number: activeSubscription?.card_number,
                    card_cvv: activeSubscription?.card_cvv,
                    payment_company_code: activeSubscription?.payment_company_code,
                    pricing_schema: activeSubscription?.pricing_schema,
                    status_vindi: activeSubscription?.status_vindi
                }))
                dispatch(updateFetchState({}));
                dispatch(fetchReceiptsSuccess(true));
                if (redirect) {
                    if (!activeSubscription?.status_vindi) data.url = APP_PATH + "/new";

                    return redirectToUrl(data.url);
                }
                return;
            }
            dispatch(fetchReceiptsSuccess(true));
            dispatch(updateFetchState({}));
            resetTokenAndRedirect();
        })
        .catch(error => {
            dispatch(updateFetchState({}));
            if (error && error?.errMsg) {
                console.log(error?.errMsg);
            }
            // 404 -> vindi or stripe: not found
            if (error.response.status === 404) return redirectToUrl(APP_PATH + "/new");
        })
};

 const _getActiveLicenses = data => async dispatch =>{
    const { clientId, } = data;

    const token = getAccessTokenFromLocalStorage();    
    const url = `/api/users?clientId=${clientId}&page=1&perPage=1000&status=1`
        
    await axios.get(`${WEB_API_URL}${url}`,{headers:{Authorization: `Bearer ${token}`}}).then((response) =>
    {
        dispatch(getActiveLicenses(response.data.length));
    })
    
}

