import React, { useState } from 'react';
import {
    QuestionButtonContainer, QuestionButton, QuestionTitleDiv, ModalQuestionStyle,
    QuestionDescription,
} from './style'
import { Text, LoadingSpin } from '../../../components'
export default function ModalQuestion({
    title, question, onClose = console.log,
    answerNo, answerYes, onClickYes, onClickNo, hasLoadingAfterConfirmation,
}) {
    const [isLoading, setLoading] = useState(false);
    return (
        <ModalQuestionStyle className={"row"} isLoading={isLoading}>
            <QuestionTitleDiv className={"col-md-12 col-sm-12 col-12"} >
                <Text flex={"1"} color={"#767676"} textTransform={"uppercase"} fontFamily={"Effra light, Roboto,sans-serif"} fontSize={"16px"}>
                    {title}
                </Text>
                <div
                    onClick={onClose} >
                    <Text color={"#767676"} cursor={"pointer"} fontFamily={"effra, Roboto,sans-serif"} fontSize={"20px"}>
                        x
                </Text>
                </div>
            </QuestionTitleDiv>
            {
                !isLoading && hasLoadingAfterConfirmation &&
                <>
                    <QuestionDescription className={"col-md-12 col-sm-12 col-12"}  >
                        <div>
                            <Text fontFamily={"Effra light, Roboto,sans-serif"} fontSize={"19px"}>
                                {question}
                            </Text>
                        </div>
                    </QuestionDescription>
                    <QuestionButtonContainer className={"col-md-12 col-sm-12 col-12"}  >
                        <div>
                            <QuestionButton border={"1px solid #1a2565"} background={"#fff"} marginRight={"5px"} onClick={onClickNo} >
                                <Text color={"#1a2565"} fontFamily={"Effra light, Roboto,sans-serif"} fontSize={"19px"}>
                                    {answerNo}
                                </Text>
                            </QuestionButton>
                            <QuestionButton border={"1px solid #fff"} background={"#1a2565"} marginLeft={"5px"}
                                onClick={() => {
                                    if (isLoading) return;
                                    onClickYes && onClickYes();
                                    hasLoadingAfterConfirmation && setLoading(true);
                                }}>
                                <Text color={"#fff"} fontFamily={"Effra light, Roboto,sans-serif"} fontSize={"19px"}>
                                    {answerYes}
                                </Text>
                            </QuestionButton>
                        </div>
                    </QuestionButtonContainer>
                </>
            }
            {
                isLoading && hasLoadingAfterConfirmation &&
                <QuestionDescription className={"col-md-12 col-sm-12 col-12"}  >
                    <LoadingSpin
                        show={isLoading}
                        weight={"4px"}
                        halfColored
                        backColor={"#4d5585"}
                        frontColor={"#ffffff"}
                        size={"50px"}
                    />
                </QuestionDescription>
            }
        </ModalQuestionStyle>
    );
}
