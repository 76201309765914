import React, { useState, useEffect } from 'react';
import { QuantitySelectorInputStyle, Add, Subtract } from './style'
import { Text } from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import { applyLicensesDiscount } from '../../store/modules';
import { MAXIMUM_LICENSES, MINIMUM_LICENSES, MINIMUM_LICENSES_ADDITIVE_NUMBER} from '../../constants/environment';

export default function QuantityInput({
    register,
    setValue,
    getValues,
    name = "qnt_licences",
    isEdit,
    ...options
}) {
    const {
        activeSubscription, activeLicenses
    } = useSelector(state => state.users);
   
    const MINIMUM_LICENSES_VALUE = isEdit ? MINIMUM_LICENSES_ADDITIVE_NUMBER : MINIMUM_LICENSES;
    const ACTIVE_LICENSES = isEdit ? (+activeSubscription?.number_licenses || 0) : 0;

    const dispatch = useDispatch();

    const [qntLicencesInputValue, setQntLicencesInputValue] = useState(MINIMUM_LICENSES_VALUE);

    setValue(name, qntLicencesInputValue + ACTIVE_LICENSES);

    const changeValue = (val) => {
        const licenses_new_value = Number(qntLicencesInputValue) + val;

        const edited_licenses = ACTIVE_LICENSES + licenses_new_value;

        const showLicences = isEdit ? edited_licenses : licenses_new_value;

        dispatch(applyLicensesDiscount({
            licenses: showLicences,
        }));
        setValue(name, showLicences);
        setQntLicencesInputValue(licenses_new_value);
    }

    const checkIfExceeded = ({ max, min }) => {
        const exceededValue = Number(max) > MAXIMUM_LICENSES;
        const belowValue = Number(min) < MINIMUM_LICENSES_VALUE;
        if (exceededValue || belowValue) return true;
        return false
    }

    const onChangeLicenses = (event) => {
        event.persist();

        const input_licenses_string = event.target.value?.toString() || "0";
        const input_licenses_without_special_chars = input_licenses_string.replace(/-/g, '');

        const input_licenses = Number(input_licenses_without_special_chars);

        const new_licenses_value_out_of_max_min_range = checkIfExceeded({
            max: input_licenses,
            min: input_licenses,
        });

        if (new_licenses_value_out_of_max_min_range) return event.preventDefault();

        const new_licenses_value_on_edit = ACTIVE_LICENSES + input_licenses;

        dispatch(applyLicensesDiscount({
            licenses: new_licenses_value_on_edit,
            plan: activeSubscription?.plan_subscription,
            type: activeSubscription?.type_subscription
        }))
        setValue(name, isEdit ? new_licenses_value_on_edit : input_licenses);
        setQntLicencesInputValue(input_licenses);
    }

    const add = (licenses) => {
        if (licenses < MAXIMUM_LICENSES) changeValue(1)
    }

    const subtract = (licenses) => {
        if (isEdit && +qntLicencesInputValue === MINIMUM_LICENSES_ADDITIVE_NUMBER) return;
        if (licenses > MINIMUM_LICENSES_VALUE) changeValue(-1);
    }

    return (
        <QuantitySelectorInputStyle {...options}>
            <Subtract type="button" onClick={(e) => {
                subtract(getValues()?.qnt_licences ?? MINIMUM_LICENSES_VALUE);
            }}>
                <Text>-</Text>
            </Subtract>
            <input
                type={"number"}
                value={qntLicencesInputValue}
                id={name}
                name={name}
                onChange={onChangeLicenses}
                ref={register({ name: name })}
            />
            <Add type="button" onClick={(e) => {
                add(getValues()?.qnt_licences ?? MINIMUM_LICENSES_VALUE);
            }}>
                <Text>+</Text>
            </Add>
        </QuantitySelectorInputStyle>
    );
}

