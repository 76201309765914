import React from 'react';
import { EditButtonStyle } from './style'
import { localizedStrings } from '../../constants/localizedStrings';

export default function EditButton({ show = true, onEdit, ...options }) {
    return (
        <EditButtonStyle show={show} onClick={() => show && onEdit()} {...options}>
            <img alt={localizedStrings.altEdit} src="https://images.contelege.com.br/contratacao/edit-button.svg" />
        </EditButtonStyle>
    );
}
