
import styled from 'styled-components';

const infoCardFixedPriceStyle = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "50%",
    margin: "0 auto",
    "& p": {
        fontFamily: "Effra light, Roboto,sans-serif",
        fontSize: "13px",
        fontWeight: "900",
        color: "#222222",
    },
    "& > div ": {
        background: "#fff",
        width: "100%",
    },
    "& > div > div": {
        display: "flex",
        justifyContent: "center",
        padding: "8px 0 8px 0",
    },

}))

const infoCardStyle = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "12px",
    "& p": {
        fontFamily: "Effra light, Roboto,sans-serif",
        fontSize: "13px",
        fontWeight: "900",
        color: "#222222",
    },
    "& > div ": {
        background: "#fff",
    },
    "& > div > div": {
        display: "flex",
        justifyContent: "center",
        padding: "8px 0 8px 0",
    },

}))
const blueBackground = styled.div(props => ({
    background: "#1A237A",
    width: "188px",
    marginLeft:"0px",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
    borderBottomRightRadius: "5px",
    borderBottomLeftRadius: "5px",
    "& p": {
        color: "#fff",
        fontWeight: "bold"
    },
}))
const whiteBackground = styled.div(props => ({
    background: "#fff",
    border: "1px solid #CBD5DD",
    borderTopRightRadius: "5px",
    borderTopLeftRadius: "5px",
    "& p": {
        color: "#222222",
    },
}))



export {
    whiteBackground as WhiteBackground,
    blueBackground as BlueBackground,
    infoCardStyle as InfoCardStyle,
    infoCardFixedPriceStyle as InfoCardFixedPriceStyle,
}
