import React from 'react';
import { HeaderContainer, LogoDiv } from './style'
import { HelpInfo } from '../../components'
import { localizedStrings } from '../../constants/localizedStrings';
const [width] = [window.innerWidth, window.innerHeight];
const isMobile = width < 860;
export default function Header({ width = window.innerWidth }) {
    return (
        <HeaderContainer>
            <div className="row" >
                <LogoDiv isMobile={isMobile} className="col-md-6 col-sm-12 col-12" >
                    <img alt={localizedStrings.altLogo} src="https://images.contelege.com.br/Landing/GE/cadastro/contele-ge-logo.png" />
                </LogoDiv>
                {width > 768 && <HelpInfo />}
            </div>
        </HeaderContainer>
    );
}
