import React from 'react';
import { Container, SuccessImage, SuccessDescription } from '../../containers'
const width = window.innerWidth;
export default function DoneSubscription({ history }) {

    return (
        <Container
            className="col-md-12 col-sm-12 col-12" maxWidth={"650px"}
            padding={width < 860 ? "32px 12px 32px 12px" : "32px 0 32px 0"}>
            <SuccessImage />
            <SuccessDescription />
        </Container>
    );
}
