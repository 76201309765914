
import styled from 'styled-components';

const summaryDiv = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    flex: "1",
    margin: "5px 10px 5px 0",
    justifyContent: "center"
}))
const summaryDivRow = styled.div(props => ({
    display: "flex",
    flexDirection: "row",
    padding: "0",
    alignItems: "flex-end"
}))

const summaryTextRow = styled.div(props => ({
    display: "flex",
    padding: "8px 0 0 0",
    flexDirection: "row",
    borderTop: props.isTitle ? "none" : "1px solid #cccccc50",
    "& > div:first-child": {
        flex: "1"
    },
    "& > div": {
        display: "flex",
        alignItems: "center",
    }
}))



export {
    summaryTextRow as SummaryTextRow,
    summaryDiv as SummaryDiv,
    summaryDivRow as SummaryDivRow,
}
