import React from 'react';
import { ReceiptItemStyle, ReceiptLinkDiv } from './style'
import { Text } from '../../../components';
import { localizedStrings } from '../../../constants/localizedStrings';
import { format, parse } from 'date-fns';
import { formatMoney } from '../../../utils/formatMoney';
const width = window.innerWidth;

const isMobile = width < 860;
export default function ReceiptItem({
    background, date, card_number, price, currency,
    payment_method, status, licenses, invoice_link: linkToReceipt
}) {

    return (
        <ReceiptItemStyle background={background} isMobile={isMobile} className={"row"}>
            <div className={"col-md-3 col-sm-3 col-3"}>
                <Text>{format(parse(date, "yyyy-MM-dd", new Date()), isMobile ? "dd/MM/yy" : "dd/MM/yyyy", new Date())}</Text>
            </div>
            <div className={"col-md-3 col-sm-3 col-3"} style={isMobile ? {} : { padding: "0px" }}>
                {
                    payment_method === "boleto"
                        ? <Text>{localizedStrings.bankSlip}</Text>
                        : <Text>
                            {
                                isMobile
                                    ? "..."
                                    : "**** **** ****"
                            } {card_number}</Text>
                }
            </div>
            <div className={"col-md-2 col-sm-2 col-2"}>
                {
                    licenses
                        ? <Text>{licenses}</Text>
                        : <Text>-</Text>
                }
            </div>
            <div className={"col-md-2 col-sm-2 col-2"}>
                <Text
                    as={isMobile && status !== "canceled" ? "a" : "p"}
                    href={isMobile && status !== "canceled" ? linkToReceipt : null}
                    target={isMobile && status !== "canceled" ? "_blank" : null} >
                    {currency + formatMoney(price)}
                </Text>
            </div>
            {
                linkToReceipt && (status === "pending" || status === "paid") &&
                <ReceiptLinkDiv isMobile={isMobile} >
                    <Text as={"a"} className={"seeReceipt"} target={"_blank"} href={linkToReceipt}>
                        {
                            status === "pending" &&
                            localizedStrings.pay
                        }
                        {
                            status === "paid" &&
                            localizedStrings.viewReceipt
                        }
                    </Text>
                </ReceiptLinkDiv>
            }
        </ReceiptItemStyle >
    );
}
