
import styled from 'styled-components';

const successDescriptionStyle = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 0 32px 0",
    flexDirection: "column",
    "& p": {
        textAlign: props.isMobile ? "center" : null,
    },
    "& > div": {
        maxWidth: "500px",
        margin: "10px 0 10px 0",
        display: "flex",
        justifyContent: "center",
        alignSelf: "center",
    },
    "&  a": {
        color: "#4596EC",
        fontSize: "15px",
        fontWeight: "bold",
        fontFamily: "Effra light,Roboto,sans-serif",
        marginLeft: "5px",
    },
    ...props,
}))
const buttonStyle = styled.button(props => ({
    margin: "0px 10px",
    background: props.background || "#1A2564",
    color: props.color || "#fff",
    fontFamily: "Effra light, Roboto,sans-serif",
    borderRadius: "2px",
    marginBottom: "5px",
    fontSize: props.isMobile ? "13px" : "15px",
    fontWeight: "bold",
    outline: "none",
    display: "flex",
    padding: props.isMobile ? "9px 9px" : "9px 13px",
    justifyContent: "center",
    alignSelf: props.alignSelf || "center",
    ...props
}))


export {
    buttonStyle as ButtonStyle,
    successDescriptionStyle as SuccessDescriptionStyle,
}
