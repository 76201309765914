import React, { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getUser } from '../../store/modules';
import { LoadingSpinner } from '../../containers'
import { getUrlParam } from '../../utils/params';
import { getAccessTokenFromLocalStorage } from '../../utils/getAccessToken';
import { localizedStrings } from '../../constants/localizedStrings';
import {LOGIN_URL} from'../../constants/environment';
export default function Authentication({ history }) {

    const dispatch = useDispatch();

    const getUserData = async () => {
        const token = getUrlParam("token")
        try{
            const validate_token = await axios.get(process.env.API_GATEWAY_URL_VALIDATE_TOKEN, 
            { headers: { 'x-cge-token-v0': `Bearer  ${token}` } });

        }catch(error){
            const message = error.message
            const hasCodeErroNotAuthorized = message.includes('401')
            if(hasCodeErroNotAuthorized === true){
             window.location.href = LOGIN_URL
            }
        }

        const lastToken = getAccessTokenFromLocalStorage();
        await dispatch(getUser({
            urlToken: token,
            lastToken,
        }));
    }
    useEffect(() => {
        getUserData()
    })


    return (
        <>
            <LoadingSpinner
                height={"100vh"}
                alignItems={"center"}
                limit={7000}
                title={localizedStrings.takingTooLong}
                description={localizedStrings.clickHereToReturnToSystem}
            />
        </>
    );
}
        /* <Container
//     containerOptions={{ height: "100%" }}
//     className="col-md-12 col-sm-12 col-12" maxWidth={"650px"}
//     padding={width < 860 ? "32px 12px 32px 12px" : "32px 0 32px 0"}>
// </Container> */
