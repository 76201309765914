
import styled from 'styled-components';


const quantitySelectorInputStyle = styled.div(props => ({
    position: "relative",
    display: "flex",
    padding: "24px 0px 0px 0px",
    "& > button": {
        background: "#EEEEEE",
        minWidth: "35px",
        outline: "none",
        display: "flex",
        justifyContent: "center",
        border: "1px solid #CBD5DD",
    },
    "& > input": {
        minWidth: "100px",
        borderTop: "1px solid #CBD5DD",
        borderBottom: "1px solid #CBD5DD",
        borderRight: "1px solid transparent",
        borderLeft: "1px solid transparent",
        textAlign: "center",
        padding: "0",
        minHeight: "40px",
        maxWidth: "100px",
        fontFamily: "effra, Roboto,sans-serif",
        fontWeight: "600",
        color: "#1A2564",
        outline: "none",
        borderRadius: "0",
    },
    "& *": {
        '-webkit-touch-callout': 'none',
        '-webkit-user-select': 'none',
        '-khtml-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none',
    },
}))

const add = styled.button(props => ({
    display: "flex",
    alignItems: "center",
    borderRadius: "0px 20px 20px 0px",
    "& > p": {
        marginRight: "4px"
    }
}))
const subtract = styled.button(props => ({
    display: "flex",
    alignItems: "center",
    borderRadius: "20px 0px 0px 20px",
    "& > p": {
        marginLeft: "4px"
    }
}))


export {
    quantitySelectorInputStyle as QuantitySelectorInputStyle,
    add as Add,
    subtract as Subtract
}
