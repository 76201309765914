import React from 'react';
import { ConsultantSelectorItemStyle } from './style'
import { Text } from '../../components'
import { localizedStrings } from '../../constants/localizedStrings';

export default function ConsultantSelectorItem({ name, value, onClickItem, index, isSelected, register }, ref) {
    return (
        <div className="col-md-4 col-sm-4 col-4" onClick={() => document.getElementById("consultant-" + value).click()} >
            <ConsultantSelectorItemStyle >
                <img alt={localizedStrings.altCurrentStep} src={
                    isSelected ?
                        "https://images.contelege.com.br/contratacao/item-selected.svg" :
                        "https://images.contelege.com.br/contratacao/item.svg"
                } />
                <Text fontSize={"15px"} color={isSelected ? "#222222" : "#767676"} padding={"5px"}>
                    {name}
                </Text>
                <input name={"consultant"} ref={register({ name: "consultant" })}
                    onClick={() => onClickItem({ name, value }, index)}
                    defaultChecked={isSelected}
                    type={"radio"} id={"consultant-" + value} />
            </ConsultantSelectorItemStyle>
        </div>
    );
}
