
import styled from 'styled-components';

const successImageStyle = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 0 32px 0",
    flexDirection: "column",
    " &> p": {
        display: props.isMobile ? "none" : "block",
        marginBottom: "40px"
    },
    " &> img": {
        maxWidth: props.isMobile ? "300px" : "400px",
    },
    ...props,
}))


export {
    successImageStyle as SuccessImageStyle,
}
