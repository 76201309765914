
import styled from 'styled-components';


const receiptLinkDiv = styled.div(props => ({
    position: "absolute",
    right: "0",
    minWidth: "50px",
    cursor: "pointer",
    "& > .seeReceipt, & > .seeReceipt:hover": {
        display: props.isMobile ? "none" : "block",
        color: "#4596EC",
        fontWeight: "bold",
    },
    ...props,
}))

const receiptItemStyle = styled.div(props => ({
    background: props.background || "#F2F2F2",
    padding: "18px 4px",
    "& > div": {
        display: props.isMobile ? "flex" : null,
        flex: props.isMobile ? "1" : null,
        alignItems: "center"
        // minWidth: ""
    },
    "& *": {
        fontFamily: "Effra light, Roboto, sans-serif",
    },
    "& > div > p": {
        color: "#000000",
        textAlign: "center"
    },
    "& > div > p, & > div > a ": {
        fontSize: "16px",
        fontWeight: "unset",

    },
    "& > div > a, & > div > a:hover": {
        color: "#4596EC !important",
        display: props.isMobile ? "block" : "none",
    },
    ...props,
}))


export {
    receiptItemStyle as ReceiptItemStyle,
    receiptLinkDiv as ReceiptLinkDiv,
}
