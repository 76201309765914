
import styled from 'styled-components';

const stepItemStyle = styled.div(props => ({
    flex: "1",
    display: "flex",
    cursor: props.selected ? "pointer" : "default",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    zIndex: "998",
    "& p": {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        fontSize: "15px",

        fontFamily: "Effra light, Roboto,sans-serif"
    },
    "& > img": {
        width: "22px",
        zIndex: "999",
        marginBottom: "5px",
    },
    ...props,
}))


export {
    stepItemStyle as StepItemStyle,

}
