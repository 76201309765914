import React, { useEffect, useState, useRef } from 'react';
import { localizedStrings } from '../../constants/localizedStrings';
import {
    Container, StepPaginator, ReturnStep, NewSubscriptionInputs
} from '../../containers'
import { goToStep, toggleStep } from '../../store/modules';
import { getAccessTokenFromLocalStorage } from '../../utils/getAccessToken';
import { verifyInputtedData } from '../../utils/verify'
import { useDispatch, useSelector } from 'react-redux';
import { createSubscription } from '../../store/modules';
import { useForm } from 'react-hook-form'
import { parseInputs } from '../../utils/inputs';
import { resetCreateStates } from '../../store/modules/subscription/action';
import { ModalElement } from '../../components';
import { AUTH_PATH } from '../../constants/environment';
const width = window.innerWidth;

export default function NewSubscription({ history }) {
    const {
        steps, currentStepIndex, createFail, createLoading
    } = useSelector(state => state.subscription);
    const {
        planTypes, prices,
    } = useSelector(state => state.prices);
    const {
        company
    } = useSelector(state => state.users);
    const resetAndGoBack = useRef(null);
    
    const { register, setValue, handleSubmit, getValues } = useForm();

    if(getValues()?.paymentSelected?.value.type === "card") steps.map(step => step.visible = true );
    
    const [currentPage, setCurrentPage] = useState(steps.filter(({ visible }) => visible)[currentStepIndex])

    const dispatch = useDispatch();


    useEffect(() => {
        setCurrentPage(
            steps.filter(({ visible }) => visible)[currentStepIndex]
        )
        // eslint-disable-next-line
    }, [currentStepIndex]);


    useEffect(() => {
        const token = getAccessTokenFromLocalStorage() ?? "";
        const hasToken = token && token.length !== 0;
        if (!hasToken) {
            window.location.href = window.location.origin + AUTH_PATH + "/" + window.location.search
        }
        // eslint-disable-next-line
    }, []);

    const submitForm = (data) => {
        const [countryPhoneId] = localizedStrings.phoneMask?.split(" ");
        const { success } = verifyInputtedData(data, { planTypes, prices });
        resetAndGoBack.current = () => {
            dispatch(resetCreateStates());
        }
        if (success) {
            const parsedData = parseInputs(data, countryPhoneId);
            dispatch(createSubscription({
                ...parsedData,
                corporateName: company?.corporateName || parsedData?.document || company.clientId,
                client_id: company.clientId,
                phone: parsedData.phone.trim(),
                country: company?.country,
                isLoading: createLoading,
            }));
            return;
        }

    }

    const goToNextStep = (step) => {
        dispatch(goToStep({
            currentStepIndex, steps,
            step: step ?? 1
        }))
    }

    const goToPreviousStep = (step) => {

        if (isNaN(step)) {
            const stepsToTake = steps
                .filter(stepObj => stepObj.visible)
                .findIndex(stepObj => stepObj.name === step);
            if (stepsToTake - currentStepIndex === 0) return;
            step = stepsToTake - currentStepIndex;
        }

        dispatch(goToStep({
            currentStepIndex, steps,
            step: step ?? -1
        }))
    }
    const toggleStepVisibility = (name = localizedStrings.creditCard) => {
        dispatch(toggleStep({ steps, name }))
    }


    return (
        <>
            <ModalElement
                show={createFail}
                title={localizedStrings.errorSubscription}
                subtitle={localizedStrings.errorCreatingSubscription}
                onClose={resetAndGoBack.current}
                showErrors
                type={"error"}
            />
            <Container>
                {
                    (currentStepIndex > 0 && currentPage?.name !== localizedStrings.confimation) &&
                    <ReturnStep lastStep={steps[currentStepIndex - 1]} goToPreviousStep={goToPreviousStep} />
                }
                <StepPaginator steps={steps} goToPreviousStep={goToPreviousStep} />
                <form onSubmit={handleSubmit(submitForm)}>
                    <NewSubscriptionInputs
                        register={register}
                        setValue={setValue}
                        getValues={getValues}
                        goToNextStep={goToNextStep}
                        goToPreviousStep={goToPreviousStep}
                        toggleStepVisibility={toggleStepVisibility}
                        currentPage={currentPage}
                    />

                </form>
            </Container>
        </>
    );
}
