import React from 'react';
import { LicensesInfoStyle, InfoItem } from './style'
import { Text } from '../../../components'
import { localizedStrings } from '../../../constants/localizedStrings';
import { format, parse } from 'date-fns';
import { formatMoney } from '../../../utils/formatMoney';

export default function LicensesInfo({ title, children, hasSeparator = false, activeSubscription, currency, dueAt, ...options }) {

    const getTotalPrice = (unitPrice = 1, totalLicenses = 1) => (Number(unitPrice) * Number(totalLicenses)).toFixed(2)

    const formatDate = (date = false) => {
        if (!date) return "";
        return format(parse(date, "yyyy-MM-dd", new Date()), "dd/MM/yyyy", new Date());
    }

    return (
        <LicensesInfoStyle {...options}>
            {
                activeSubscription?.corporateName &&
                <Text fontSize={"17px"} color={"#555555"} marginBottom={"24px"} overflow={"hidden"} text-overflow={"ellipsis"}>
                    {activeSubscription?.corporateName}
                </Text>
            }
            {
                activeSubscription?.number_licenses &&
                <InfoItem>
                    <Text>{localizedStrings.contractedLicenses}: </Text><span>{activeSubscription?.number_licenses}</span>
                </InfoItem>
            }
            {
                activeSubscription?.due_at &&
                <InfoItem>
                    <Text>{localizedStrings.nextChargeDate}: </Text><span>{formatDate(activeSubscription?.due_at)}</span>
                </InfoItem>
            }
            {
                activeSubscription?.price_per_license && activeSubscription?.number_licenses &&
                <InfoItem>
                    <Text>{localizedStrings.invoiceAmount}: </Text>
                    <span>
                        {currency}
                        {formatMoney(
                            getTotalPrice(activeSubscription?.price_per_license, activeSubscription?.number_licenses)
                        )
                        }
                    </span>
                </InfoItem>
            }
        </LicensesInfoStyle >
    );
}
