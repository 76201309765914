
import styled from 'styled-components';


const inputDivStyle = styled.div(props => ({
    display: "flex",
    justifyContent: props.justifyContent || "center",
    alignItems: props.alignItems || "flex-start",
    flexDirection: "column",
    padding: props.padding || "10px 15px",
    width: props.width || "100%",
    "& > div": {
        width: "100%",
    },
    "&  input, & > select": {
        padding: "8px",
        margin: "5px 0",
        width: props.inputWidth || "100%",
        border: props.hasError ? "1px solid #dc3545" : "1px solid #CBD5DD",
        borderRadius: "2px",
        outline: "none",
        height: props.isMobile ? "38px" : "32px",
        background: "#fff",
        color: "#444444",
        fontWeight: "bold",
        fontSize: "15px",
        fontFamily: "Effra light, Roboto,sans-serif",
    },
    "& > label:last-child": {
        color: "#AAAAAA"
    },
    "& > label:first-child": {
        color: "#767676"
    },
    "& > button": {
        fontSize: "15px",
        fontFamily: "Effra light, Roboto,sans-serif",
        background: "#1A2564",
        color: "#fff",
        borderRadius: "2px",
        marginBottom: "5px",
        fontWeight: "bold",
        outline: "none",
        display: "flex",
        padding: "9px 13px",
        justifyContent: "center",
        alignSelf: props.alignSelf || "stretch",
    },
    ...props
}))


export {
    inputDivStyle as InputDivStyle,
}
