import React from 'react';
import { QuantitySelectorStyle, } from './style'
import { QuantitySelectorInput, Text } from '../../components'
import { localizedStrings } from '../../constants/localizedStrings';
// const [width, height] = [window.innerWidth, window.innerHeight];
// const isMobile = width < 860;
export default function QuantitySelector({ register, setValue, getValues, isEdit,...options }) {
    return (
        <QuantitySelectorStyle {...options}>
            <Text fontSize={"20px"} textAlign="center">
                {localizedStrings.enterTheNumberOfEmployees}
            </Text>
            <QuantitySelectorInput
                register={register}
                setValue={setValue}
                getValues={getValues}
                isEdit={isEdit}
            />
        </QuantitySelectorStyle>
    );
}
