import creditCardType from "credit-card-type";
const getCardType = (number = false) => {
    try {
        number = number.replace(" ", "")
        const card = creditCardType(number);
        const type = card[0].type.replace("-", "_");
        return type;
    } catch (error) {
        return "";
    }
}

export {
    getCardType
}