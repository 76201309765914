
import styled from 'styled-components';

const userFieldsStyle = styled.div(props => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "40px 0 0px 0 ",
    "& > div": {
        maxWidth: "500px",
        width: "100%"
    }
}))
const inputDivStyle = styled.div(props => ({
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    padding: "10px 0",
    "& > input": {
        padding: "8px",
        margin: "5px 0",
        width: props.width || "100%",
        border: props.hasError ? "1px solid #dc3545" : "1px solid #CBD5DD",
        borderRadius: "2px",
        outline: "none",
        color: "#444444",
        fontWeight: "bold",
        fontFamily: "Effra light, Roboto,sans-serif",
        fontSize: "15px",
    },
    "& > label": {
        fontSize: "15px",
        fontFamily: "Effra light, Roboto,sans-serif"
    },
    "& > label:last-child": {
        color: "#AAAAAA"
    },
    "& > label:first-child": {
        color: "#767676"
    },
}))


export {
    inputDivStyle as InputDivStyle,
    userFieldsStyle as UserFieldsStyle,

}
