
import styled from 'styled-components';

const modalQuestionStyle = styled.div(props => ({
    height: props.hasImage ? "300px" : "250px", 
    margin: "0px"
}))
const questionTitleDiv = styled.div(props => ({
    display: "flex", justifyContent: "center", alignItems: "center", maxHeight: "50px", borderBottom: "1px solid #ccc",
    "& > div": {
        height: "100%",
        width: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    }
}))
const questionDescription = styled.div(props => ({
    display: "flex", justifyContent: "center", alignItems: "center",
    "& > div": {
        display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center",
        flexDirection: "column",
        height: "100%",
        padding: "15px 0px",
    }
}))
const questionButton = styled.div(props => ({
    borderRadius: "4px",
    flex: "1",
    padding: "8px",
    cursor: "pointer",
    "& > *": {
        cursor: "pointer",
    },
    ...props,
}))
const questionButtonContainer = styled.div(props => ({
    display: "flex",
    justifyContent: "center", alignItems: "center",
    "& > div": {
        display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", flex: "1"
    }
}))



export {
    questionButtonContainer as QuestionButtonContainer,
    questionButton as QuestionButton,
    questionTitleDiv as QuestionTitleDiv,
    modalQuestionStyle as ModalQuestionStyle,
    questionDescription as QuestionDescription,
}
