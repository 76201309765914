import { combineReducers } from "redux";

import prices from "./prices/reducer";
import subscription from "./subscription/reducer";
import users from "./users/reducer";

export default combineReducers({
    prices,
    subscription,
    users,
});