
import styled from 'styled-components';


const linksList = styled.div(props => ({
    "& > li": {
        listStyle: "none",
        marginBottom: "12px",
    },
    "& > li a": {
        fontSize: "15px",
        cursor: "pointer",
        color: "#4596EC",
        fontFamily: "Effra light, Roboto,sans-serif",
        fontWeight: "900"
    },
    "& > li a:hover": {
        color: "#4596EC"
    },
}))
const paymentMethodInfoStyle = styled.div(props => ({
    marginBottom: "24px"
}))
const paymentMethodDiv = styled.div(props => ({
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",
    "& > img": {
        width: "31px",
        marginRight: "8px"
    }
}))


export {
    linksList as LinksList,
    paymentMethodDiv as PaymentMethodDiv,
    paymentMethodInfoStyle as PaymentMethodInfoStyle,
}
