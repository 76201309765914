import React, { useEffect, useRef } from 'react';
import {
    RegisterSubscription
} from '../../../containers'
import { useForm } from 'react-hook-form'
import { localizedStrings } from '../../../constants/localizedStrings';
import { useDispatch, useSelector } from 'react-redux';
import { updateSubscription, resetUpdateStates } from '../../../store/modules/subscription/action';
import { parseInputs } from '../../../utils/inputs';
import { ModalElement } from '../../../components';
export default function EditAddress({
    history,
}) {
    const resetAndGoBack = useRef(null)
    let formRef = useRef(null)
    const {
        activeSubscription, company
    } = useSelector(state => state.users);
    const {
        address,
        updateSuccess, updateFail, updateLoading,
    } = useSelector(state => state.subscription);
    const { register, setValue, handleSubmit, getValues, formState } = useForm({
        defaultValues: {
            document: company?.identification,
            phone: company?.phone,
            emails: company?.email,
            cep: company?.address?.cep || activeSubscription.zipcode,
            address: activeSubscription?.address,
            addressNumber: activeSubscription?.number,
            complement: activeSubscription?.complement,
            neighborhood: activeSubscription?.neighborhood,
            city: activeSubscription?.city,
            state: activeSubscription?.state,
        }
    });
    const { dirty } = formState;
    const dispatch = useDispatch();

    useEffect(() => {
        if (address.hasOwnProperty("address")) {
            let newAddress = {}
            const addressItens = Object.keys(address);
            addressItens.map(item => {
                setValue(item, address[item] ?? "");
                newAddress[item] = address[item] ?? "";
                return item;
            });
        }
        // eslint-disable-next-line
    }, [address]);

    const submitForm = (data) => {
        const [countryPhoneId] = localizedStrings.phoneMask?.split(" ");
        const parsedData = parseInputs(data, countryPhoneId);

        resetAndGoBack.current = () => {
            dispatch(resetUpdateStates());
        }
        dispatch(updateSubscription({
            activeSubscription, newSubscription: parsedData,
            isLoading: updateLoading,
        }))
    }
    return (
        <form onSubmit={handleSubmit(submitForm)} ref={ref => formRef = ref}>
            <ModalElement
                show={updateFail}
                title={localizedStrings.errorSubscription}
                subtitle={localizedStrings.errorUpdatingSubscription}
                onClose={resetAndGoBack.current}
                showErrors
                type={"error"}
            />
            <ModalElement
                show={updateSuccess}
                onClose={resetAndGoBack.current}
                title={localizedStrings.modalSuccessTitle}
                subtitle={localizedStrings.modalSuccessSubtitle}
                type={"success"}
            />
            <RegisterSubscription
                register={register}
                setValue={setValue}
                getValues={getValues}
                edittion
                goToNextStep={() => dirty && formRef.dispatchEvent(new Event('submit', { cancelable: true }))} // triggers submit
                isDisabled={!dirty}
                isLoading={updateLoading}
            />
        </form>
    );
}
