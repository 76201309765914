
import styled from 'styled-components';

const headerContainer = styled.div(props => ({
    padding: "12px 0",
    display: "flex",
    width: "100%",
    maxWidth: "650px",
    minHeight: "73px",
    justifyContent: "center",
    borderBottom: "1px solid #cccccc50",
    "& > div": {
        width: "100%"
    }
}));
const logoDiv = styled.div(props => ({
    display: "flex",
    justifyContent: props.isMobile ? "center" : "flex-start",
    alignItems: "center",
    "& > img": {
        maxWidth: "180px"
    }
}));


export {
    headerContainer as HeaderContainer,
    logoDiv as LogoDiv,
}
