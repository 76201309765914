
import styled from 'styled-components';

const structureStyle = styled.div(props => ({
    background: props.background || "#F2F2F2",
    display: props.display || "flex",
    minHeight: props.minHeight || "100vh",
    justifyContent: props.justifyContent || "center",
    alignItems: props.alignItems || "flex-start",
    height: props.height,
    paddingBottom: "25px",
    ...props,
}))
const contentWrapper = styled.div(props => ({
    background: props.background || "#FFFFFF",
    alignItems: props.alignItems || "center",
    flexDirection: props.flexDirection || "column",
    width: props.width,
    padding: props.padding || "12px 0",
    display: props.display || "flex",
    justifyContent: props.justifyContent || "center",
    borderBottomRightRadius: "15px",
    borderBottomLeftRadius: "15px",
    ...props,
}));
const headerContainer = styled.div(props => ({
    padding: props.padding || "12px 24px",
    display: props.display || "flex",
    justifyContent: props.justifyContent || "center",
    borderBottom: props.borderBottom || "1px solid #cccccc50",
    "& > div": {
        width: props.width || "100%"
    },
    ...props,
}));
const logoDiv = styled.div(props => ({
    display: props.display || "flex",
    justifyContent: props.justifyContent || "center",
    alignItems: props.alignItems || "center",
    "& > img": {
        maxWidth: props.maxWidth || "180px"
    },
    ...props,
}));
const mainContent = styled.div(props => ({
    display: props.display || "flex",
    justifyContent: props.justifyContent || "flex-start",
    alignItems: props.alignItems || "flex-start",
    flexDirection: props.flexDirection || "column",
    ...props,
}));


export {
    structureStyle as StructureStyle,
    contentWrapper as ContentWrapper,
    headerContainer as HeaderContainer,
    logoDiv as LogoDiv,
    mainContent as MainContent,
}
