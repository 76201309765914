import React, { useState, useEffect, useRef } from 'react';
import {
    RegisterCreditCard
} from '../../../containers'
import { useForm } from 'react-hook-form'
import { localizedStrings } from '../../../constants/localizedStrings';
import { useDispatch, useSelector } from 'react-redux';
import { updateSubscription, resetUpdateStates } from '../../../store/modules/';
import { parseInputs } from '../../../utils/inputs';
import { ModalElement } from '../../../components';
import { format } from 'date-fns';
export default function EditCreditCard({
    history
}) {
    const { register, setValue, handleSubmit, getValues } = useForm({
        defaultValues: {
            expirationDate: format(new Date(), "MM/yyyy", new Date()),
        }
    });
    const [previousValues, setPreviousValues] = useState({});
    const resetAndGoBack = useRef(null)
    let formRef = useRef(null)
    const dispatch = useDispatch();
    const {
        activeSubscription
    } = useSelector(state => state.users);
    const {
        updateSuccess, updateFail, updateLoading,
    } = useSelector(state => state.subscription);

    const getDefaultValues = () => {
        if (!activeSubscription) return;
        return {
            cardName: activeSubscription?.holder_name,
            expirationDate: activeSubscription?.card_expiration,
        }
    }
    useEffect(() => {
        setPreviousValues(getDefaultValues());
        // eslint-disable-next-line
    }, [activeSubscription]);


    const submitForm = (data) => {
        resetAndGoBack.current = () => {
            dispatch(resetUpdateStates());
        }
        const parsedData = parseInputs(data);

        dispatch(updateSubscription({
            activeSubscription, newSubscription: parsedData,
            isLoading: updateLoading,
        }))
    }

    return (
        <form onSubmit={handleSubmit(submitForm)} ref={(ref) => formRef = ref}>
            <ModalElement
                show={updateFail}
                title={localizedStrings.errorSubscription}
                subtitle={localizedStrings.errorUpdatingSubscription}
                onClose={resetAndGoBack.current}
                showErrors
                type={"error"}
            />
            <ModalElement
                show={updateSuccess}
                onClose={resetAndGoBack.current}
                title={localizedStrings.modalSuccessTitle}
                subtitle={localizedStrings.modalSuccessSubtitle}
                type={"success"}
            />
            <RegisterCreditCard
                register={register}
                setValue={setValue}
                getValues={getValues}
                goToNextStep={() => formRef.dispatchEvent(new Event('submit', { cancelable: true }))} // triggers submit
                defaultValues={previousValues}
                edittion={true}
                isLoading={updateLoading}
            />
        </form>
    );
}
