
import styled from 'styled-components';

const quantitySelectorStyle = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "24px 0 32px 0",
    flexDirection: "column",
    ...props,
}))


export {
    quantitySelectorStyle as QuantitySelectorStyle,
}
