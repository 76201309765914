import React from 'react';
import { PriceBlockStyle, PriceStyle, } from './style'
import { Text } from '../../../components';
import { localizedStrings } from '../../../constants/localizedStrings';
import { MINIMUM_LICENSES } from '../../../constants/environment';
import { formatMoney } from '../../../utils/formatMoney';

export default function PriceBlock({ price, rawPrice, value, licenses, currency = "R$", selected }) {

    const numberOfPaymentsByPlan = {
        "monthly": 1,
        "quarterly": 3,
        "semester": 6,
        "annual": 12
    }

    const textCashValuePer = {
        "monthly": localizedStrings.cashValueMonthly,
        "quarterly": localizedStrings.cashValuePerQuarter,
        "semester": localizedStrings.cashValuePerSemester,
        "annual": localizedStrings.cashValuePerYear
    };

    const getPriceMultiplyByLicenses = ({
        price, 
        licenses,
        plan_specification
    }) => {
        const paymentCiclesByPlan = numberOfPaymentsByPlan[plan_specification.plan]

        return (Number(paymentCiclesByPlan) * (Number(price) * Number(licenses ?? MINIMUM_LICENSES))).toFixed(2);
    };

    const priceMultiplyByLicenses = getPriceMultiplyByLicenses({
        price: rawPrice,
        licenses,
        plan_specification: value
    });

    const hasDiscountOnPlan = Number(rawPrice) > Number(price);
    
    const priceWithDiscountMultiplyByLicenses = getPriceMultiplyByLicenses({
        price,
        licenses,
        plan_specification: value
    });

    const discountedValue = priceMultiplyByLicenses - priceWithDiscountMultiplyByLicenses;

      return (
        <PriceBlockStyle  >
            <PriceStyle selected={selected} style={{marginTop: hasDiscountOnPlan ? "32px" : "44px",marginLeft:"20px",}}>
            <div style={{display:'flex',marginBottom:"4px"}}>
                {hasDiscountOnPlan &&
                    <Text fontSize="13px" color="#969696">
                        <span style={{fontWeight:"400"}}>
                            {localizedStrings.before}
                        </span>
                        &nbsp;
                        <strike>
                            {`${currency}${formatMoney(priceMultiplyByLicenses)}`}
                        </strike>
                    </Text>
                }
            </div>
            <div style={{display:'flex'}}>
                <span style={{fontSize:"14px",fontWeight:"700",color:"#6A6A84",marginTop:"4px"}}>{currency}</span>
                <Text
                    fontSize="28px"
                    color={"#1A237A"}
                    fontFamily={(selected ? "Effra" : "Effra light") + ", sans-serif "}
                >
                    {hasDiscountOnPlan ? formatMoney(priceWithDiscountMultiplyByLicenses) : formatMoney(priceMultiplyByLicenses)}
                </Text>
            </div>
            <div 
                style={{
                    width:"max-content",
                    height:"20px",
                    color: "#fff",
                    background: hasDiscountOnPlan ? "#41BA40" : "#6C757D",
                    fontSize:"11px",
                    fontWeight:"500",
                    display:'flex',
                    alignItems:"center",
                    padding:"0 8px",
                    marginTop:"10px"
                }}
            >
               {hasDiscountOnPlan ? `${localizedStrings.DiscountOf} ${currency}${formatMoney(discountedValue)}` : `${localizedStrings.thereIsNoDiscount}`} 
            </div>
            <span style={{fontSize:"10px",color:"#6A6A84",marginTop:"4px"}}>*{textCashValuePer[value.plan]}.</span>
            </PriceStyle>
          
        </PriceBlockStyle>
    );
}
