import { localizedStrings } from "../constants/localizedStrings";
import { isDate } from 'date-fns'

const verifyInputtedData = (data, availablePlans) => {
    if (data.qnt_licences < 0) return { screen: localizedStrings.licenses, success: false };

    const planExists = availablePlans.planTypes.filter(planType => planType?.value === data.paymentSelected?.value?.type).length
    if (planExists === 0) return { screen: localizedStrings.licenses, success: false };

    const isPlanValid = availablePlans.prices.filter(price => price?.value?.type === data.paymentSelected?.value?.type)[data.paymentSelected.index]
    if (isPlanValid === 0) return { screen: localizedStrings.licenses, success: false };

    const validDueDate = isDate(data.dueDate);
    if (validDueDate) return { screen: localizedStrings.confimation, success: false };

    return { success: true };
};

export { verifyInputtedData }