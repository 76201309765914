import React, { useState, useEffect } from 'react';
import { InfoSummaryStyle, IconStyle } from './style'
import { Text, PaymentSummaryItem, FieldBlock } from '../../../components'
import { useSelector } from 'react-redux';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { localizedStrings, userLang } from './../../../constants/localizedStrings';
import { addDays, format } from 'date-fns';
import { getPricePerLicense } from '../../../utils/pricePerLicense';
import { formatMoney } from '../../../utils/formatMoney';
export default function InfoSummary({
    getValues, setValue, register, summaryReadOnly = false,
    editLicenses, editPlan, editPaymentMethod, selectedPlan, ...options
}) {
    const [startDate, setStartDate] = useState(new Date());

    const {
        company
    } = useSelector(state => state.users)
    const {
        planTypes
    } = useSelector(state => state.prices)
    useEffect(() => {
        registerLocale(userLang, {
            localize: {
                month: n => localizedStrings.monthNamesShort[n],
                day: n => localizedStrings.dayNamesShort[n]
            },
            formatLong: {}
        });
    }, []);

    const getPaymentMethod = () => {
        if (!selectedPlan?.value) return;
        const [paymentSelected] = planTypes.filter(planType => planType.value === selectedPlan?.value?.type);
        return paymentSelected?.name;
    }

    const getLicensesQuantity = () => Number(getValues()?.qnt_licences || selectedPlan?.licencesQuantity);

    const getFullChargePrice = ({ plan }) => {
        if(!selectedPlan) return;
        let monthyPrice = (Number(getLicensesPrice()) * getLicensesQuantity());
        
        return formatMoney(monthyPrice.toFixed(2))
    }

    const getLicensesPrice = () => {
        if (!selectedPlan?.value?.type) return;
        const {
            price
        } = getPricePerLicense({
            licenses: getLicensesQuantity(),
            plan: summaryReadOnly ? selectedPlan?.value?.value : getValues()?.paymentSelected?.value?.plan,
            type: summaryReadOnly ? selectedPlan?.value?.type : getValues()?.paymentSelected?.value?.type,
            pricing_schema: selectedPlan?.pricing_schema
        });

        return Number(price).toFixed(2);
    }

    const icon = () => <IconStyle src="https://images.contelege.com.br/contratacao/calendar.svg" />;

    const datePicker = () => <DatePicker
        selected={startDate} ref={register({ name: "dueDate" })}
        onChange={date => {
            setStartDate(date);
            const formattedDate = format(date, "yyyy-MM-dd");
            setValue("dueDate", formattedDate)
        }}
        dateFormat={localizedStrings.dateMask}
        locale={userLang}
        onChangeRaw={(e) => e.preventDefault()}
        minDate={new Date()}
        maxDate={addDays(new Date(), 7)}
    />

    return (
        <InfoSummaryStyle {...options}>
            <div className={"row"}>
                {
                    !summaryReadOnly &&
                    <Text fontSize={"19px"} marginBottom={"20px"} >
                        {localizedStrings.subscriptionSummary}
                    </Text>
                }
                <PaymentSummaryItem
                    name={localizedStrings.licensesNumber}
                    value={getLicensesQuantity() + " " + localizedStrings.licenses}
                    hasEdition
                    isSummaryItem={!summaryReadOnly}
                    isTitle
                    onEdit={!summaryReadOnly && editLicenses}
                />
                <PaymentSummaryItem
                    name={localizedStrings.licensesPrice}
                    value={company.currency + " " + formatMoney(getLicensesPrice())}
                />
                <PaymentSummaryItem
                    name={localizedStrings.invoiceAmount}
                    value={company.currency + " " + getFullChargePrice({
                        plan: summaryReadOnly ? selectedPlan?.value?.value : getValues()?.paymentSelected?.value?.plan
                    })}
                />
                <PaymentSummaryItem
                    name={localizedStrings.selectedPlan}
                    value={selectedPlan?.name?.toUpperCase()}
                    hasEdition
                    isSummaryItem={!summaryReadOnly}
                    onEdit={!summaryReadOnly && editPlan}
                />
                <PaymentSummaryItem
                    name={localizedStrings.paymentMethod}
                    value={getPaymentMethod()}
                    hasEdition
                    isSummaryItem={!summaryReadOnly}
                    onEdit={!summaryReadOnly && editPaymentMethod}
                />
                <FieldBlock
                    text={localizedStrings.expirationDate} padding={"25px 0px 0px 0px"} bottomComponent={icon} show={selectedPlan?.value?.type === "boleto" && !summaryReadOnly}
                    textOptions={{ as: "label", htmlFor: "dueDate", fontFamily: "Effra light, Roboto,sans-serif", fontSize: "15px" }}
                    customInput={datePicker} inputName={"dueDate"} inputOptions={{ id: "dueDate", name: "dueDate", type: "number", }}
                    defaultClasses={"col-md-12 col-sm-12 col-12"}
                />

            </div>
        </InfoSummaryStyle >
    );
}
