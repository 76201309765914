const getUrlParam = (sParam) => {
    const sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&');
    let sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};

const setUrlParam = (key, value) => {
    if (!window.history.pushState || !key) return;
    let url = new URL(window.location.href);
    const params = new window.URLSearchParams(window.location.search);

    if (value === undefined || value === null) {
        params.delete(key);
    } else {
        params.set(key, value);
    }

    url.search = params;
    url = url.toString();
    window.history.replaceState({ url: url }, null, url);
}

export { getUrlParam, setUrlParam }