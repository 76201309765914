import React, { useEffect, useState } from 'react';
import { localizedStrings } from '../../constants/localizedStrings';
import {
    Container, EditLicenses, EditCreditCard, ReturnStep, EditPaymentMethod, EditAddress
} from '../../containers'
import { useDispatch, } from 'react-redux';
import { resetUpdateStates } from '../../store/modules';
import { getUrlParam } from '../../utils/params';
import { APP_PATH, AUTH_PATH } from '../../constants/environment';
import { getAccessTokenFromLocalStorage } from '../../utils/getAccessToken';
const width = window.innerWidth;
export default function EditSubscription({ history }) {


    const getParam = () => {
        const availableParams = ["licenses", "card", "payment", "address"]
        const param = getUrlParam("edit");
        if (availableParams.includes(param)) return param;
        window.location.href = window.location.origin + APP_PATH + "/review"
    }
    const [currentItemToEdit,] = useState(getParam() ?? false)

    const dispatch = useDispatch();

    useEffect(() => {
        const token = getAccessTokenFromLocalStorage() ?? "";
        const hasToken = token && token.length !== 0;

        if (!hasToken) {
            window.location.href = window.location.origin + AUTH_PATH + "/" + window.location.search
        }
        dispatch(resetUpdateStates())
        // eslint-disable-next-line
    }, []);

    const goToPreviousStep = () => history.goBack()

    return (
        <Container
            className="col-md-12 col-sm-12 col-12" maxWidth={"650px"}
            padding={width < 860 ? "32px 12px 32px 12px" : "32px 0 32px 0"}>

            <ReturnStep lastStep={{ name: localizedStrings.billingData }} goToPreviousStep={goToPreviousStep} />
            <div className="col-md-12 col-sm-12 col-12" >
                {
                    currentItemToEdit === "licenses" &&
                    <EditLicenses
                        history={history}
                    />
                }
                {
                    currentItemToEdit === "payment" &&
                    <EditPaymentMethod
                        history={history}
                    />
                }
                {
                    currentItemToEdit === "card" &&
                    <EditCreditCard
                        history={history}
                    />
                }
                {
                    currentItemToEdit === "address" &&
                    <EditAddress
                        history={history}
                    />
                }

            </div>
        </Container>
    );
}
