import { localizedStrings } from "../../../constants/localizedStrings"

export const UPDATE_STEPS = 'update_steps'
export const FETCH_ADDRESS_SUCCESS = 'fetch_address_success'
export const RESET_UPDATE_STATES = 'reset_update_states'
export const UPDATE_UPDATE_STATES = 'update_update_states'
export const RESET_ADDRESS_STATES = 'reset_address_states'
export const UPDATE_ADDRESS_STATES = 'update_address_states'
export const RESET_CREATE_STATES = 'reset_create_states'
export const UPDATE_CREATE_STATES = 'update_create_states'


const INITIAL_STATE = {
    subscription: {},
    createSuccess: false,
    createFail: false,
    createLoading: false,
    createErrors: [],
    updateSuccess: false,
    updateFail: false,
    updateLoading: false,
    updateErrors: [],
    limitExceeded: false,
    steps: [
        {
            key: "licenses",
            visible: true,
            name: localizedStrings.licenses,
            selected: true,
        },
        {
            key: "creditCard",
            visible: false,
            name: localizedStrings.creditCard,
            selected: false,
        },
        {
            key: "registations",
            visible: true,
            name: localizedStrings.registations,
            selected: false,
        },
        {
            key: "confimation",
            visible: true,
            name: localizedStrings.confimation,
            selected: false,
        },
    ],
    currentStepIndex: 0,
    address: {},
    addressSuccess: false,
    addressFail: false,
    addressLoading: false,
    states: [
        { name: "Acre", minifiedName: "AC", selected: false },
        { name: "Alagoas", minifiedName: "AL", selected: false },
        { name: "Amapá", minifiedName: "AP", selected: false },
        { name: "Amazonas", minifiedName: "AM", selected: false },
        { name: "Bahia", minifiedName: "BA", selected: false },
        { name: "Ceará", minifiedName: "CE", selected: false },
        { name: "Distrito Federal", minifiedName: "DF", selected: false },
        { name: "Espirito Santo", minifiedName: "ES", selected: false },
        { name: "Goiás", minifiedName: "GO", selected: false },
        { name: "Maranhão", minifiedName: "MA", selected: false },
        { name: "Mato Grosso do Sul", minifiedName: "MS", selected: false },
        { name: "Mato Grosso", minifiedName: "MT", selected: false },
        { name: "Minas Gerais", minifiedName: "MG", selected: false },
        { name: "Pará", minifiedName: "PA", selected: false },
        { name: "Paraíba", minifiedName: "PB", selected: false },
        { name: "Paraná", minifiedName: "PR", selected: false },
        { name: "Pernambuco", minifiedName: "PE", selected: false },
        { name: "Piauí", minifiedName: "PI", selected: false },
        { name: "Rio de Janeiro", minifiedName: "RJ", selected: false },
        { name: "Rio Grande do Norte", minifiedName: "RN", selected: false },
        { name: "Rio Grande do Sul", minifiedName: "RS", selected: false },
        { name: "Rondônia", minifiedName: "RO", selected: false },
        { name: "Roraima", minifiedName: "RR", selected: false },
        { name: "Santa Catarina", minifiedName: "SC", selected: false },
        { name: "São Paulo", minifiedName: "SP", selected: false },
        { name: "Sergipe", minifiedName: "SE", selected: false },
        { name: "Tocantins", minifiedName: "TO", selected: false },
    ],
};



export default function subscriptions(state = INITIAL_STATE, action) {

    const actionTypes = {
        update_steps() { return { ...state, steps: [...action.payload.steps], currentStepIndex: action.payload.currentStepIndex ?? state.currentStepIndex } },
        fetch_address_success() {
            return {
                ...state,
                address: { ...action.payload.address },
                states: state.states.map(itemState => {
                    if (itemState.minifiedName?.toLowerCase() === action.payload.address.state?.toLowerCase()) {
                        return { ...itemState, selected: true }
                    }
                    return { ...itemState, selected: false }
                })
            }
        },
        reset_update_states() {
            return {
                ...state,
                updateSuccess: false,
                updateFail: false,
                updateLoading: false,
                updateErrors: [],
            }
        },
        update_update_states() {
            return {
                ...state,
                updateSuccess: action.payload.updateSuccess,
                updateFail: action.payload.updateFail,
                updateLoading: action.payload.updateLoading,
                updateErrors: action.payload.updateErrors,
                limitExceeded: action.payload.limitExceeded,
            }
        },
        reset_address_states() {
            return {
                ...state,
                addressSuccess: false,
                addressFail: false,
                addressLoading: false,
            }
        },
        update_address_states() {
            return {
                ...state,
                addressSuccess: action.payload.addressSuccess,
                addressFail: action.payload.addressFail,
                addressLoading: action.payload.addressLoading,
            }
        },
        reset_create_states() {
            return {
                ...state,
                createSuccess: false,
                createFail: false,
                createLoading: false,
                createErrors: [],
            }
        },
        update_create_states() {
            return {
                ...state,
                createSuccess: action.payload.createSuccess,
                createFail: action.payload.createFail,
                createLoading: action.payload.createLoading,
                createErrors: action.payload.createErrors
            }
        },
    }

    return actionTypes?.[action.type]?.() ?? state;
}
