
import styled from 'styled-components';


const consultantSelectorItemStyle = styled.div(props => ({
    display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row",
    cursor: props.hasDefaultConsultant ? "default" : "pointer", 
    padding: "0 0 12px 0",
    "& > img": {
        width: "18px",
    },
    "& > input": {
        display: "none"
    },
    "& > p": {
        color: props.hasDefaultConsultant && "#919191"
    },
}))


export {
    consultantSelectorItemStyle as ConsultantSelectorItemStyle,
}
