import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export default reducers => {
    const persistedReducer = persistReducer(
        {
            key: 'contelege:subscription',
            storage: storage,
            whitelist: ['users', 'prices']
        },
        reducers
    );

    return persistedReducer;
};