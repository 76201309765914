
import styled from 'styled-components';


const priceBlockStyle = styled.div(props => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    "& > div": {
        display: "flex"
    },
    "& span": {
        color: props.selected ? "#1A2564" : "#222222",
        fontFamily: (props.selected ? "Effra" : "Effra light") + ", sans-serif ",
    }
}))

export {
    priceBlockStyle as PriceBlockStyle,
}
