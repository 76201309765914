
import {
    CHANGE_PRICE_TO_US,
    CHANGE_PRICE_TO_BR,
    APPLY_LICENSES_DISCOUNT
} from './reducer';


export function changeCardPriceToUS() {
    return {
        type: CHANGE_PRICE_TO_US,
    };
}
export function changeCardPriceToBR() {
    return {
        type: CHANGE_PRICE_TO_BR,
    };
}
export function applyDiscount({ licenses, }) {
    return {
        type: APPLY_LICENSES_DISCOUNT,
        payload: {
            licenses,
        }
    };
}

export const applyLicensesDiscount = data => async dispatch => {
    const { licenses = "", } = data;

    if (licenses === 0 || licenses.length === 0) return false;

    dispatch(applyDiscount({
        licenses,
    }))
}

