import React from 'react';
import { StepContainer, Path } from './style'
import { StepItem } from '../../components'
export default function StepPaginator({ steps, goToPreviousStep }) {
    const hasNotVisibleSteps = steps?.filter(step => !step.visible).length > 0;

    
    return (
        <StepContainer>
            {
                steps.map((step, index) => <StepItem index={index} key={index} step={step} goToPreviousStep={goToPreviousStep} />)
            }
            <Path width={hasNotVisibleSteps ? "70%" : "72%"} />
        </StepContainer>
    );
}
