import React, { useState, useEffect, useRef } from 'react';
import {
    PaymentMethodSelector
} from '../../../containers'
import {
    ModalElement,
} from '../../../components'
import { useForm } from 'react-hook-form'
import { localizedStrings } from '../../../constants/localizedStrings';
import { useDispatch, useSelector } from 'react-redux';
import { updateSubscription, resetUpdateStates } from '../../../store/modules';
export default function EditPaymentMethod({
    name = "paymentSelected", history,
}) {
    const { register, setValue, handleSubmit, getValues } = useForm()
    const dispatch = useDispatch();
    const confirmationRef = useRef(null)
    const resetAndGoBack = useRef(null);

    const {
        updateSuccess, updateFail, updateLoading,
    } = useSelector(state => state.subscription);
    const {
        activeSubscription
    } = useSelector(state => state.users);
    const [isModalVisible, setModalVisible] = useState(false);

    const [paymentSelected, setPaymentSelected] = useState(
        getValues()?.paymentSelected ??
        {
            index: -1, value: { type: "boleto" }
        })

    const plans = {
        boleto: (plan_subscription) => {
            let paymentData = {
                index: -1, value: { type: "boleto" }
            }
            if (plan_subscription === "semester") paymentData.index = 1;
            if (plan_subscription === "quarterly") paymentData.index = 0;
            if (plan_subscription === "annual") paymentData.index = 2;
            return paymentData;
        },
        card: () => {
            return {
                index: 0, value: { type: "card" }
            }
        },
    }

    const selectPaymentMethod = (planType, index) => {
        setValue(name, { value: { type: planType?.value } });
        setPaymentSelected({ value: { type: planType?.value } });
    }

    const isSamePlan = (planSelected = {}, previousPlan) => {
        const { paymentSelected: plan } = planSelected;

        const notSamePlan = plan.index !== previousPlan.index;
        const planIsNull = plan?.value?.type === null;
        const notSamePlanType = plan?.value?.type !== previousPlan?.value?.type;

        if (notSamePlan || planIsNull || notSamePlanType) return false;

        return true;
    }
    const selectPlan = (price, index) => {
        setValue(name, { index, ...price, });
        setPaymentSelected({ index, ...price })
    }

    useEffect(() => {
        if (updateFail || updateSuccess) {
            setModalVisible(false);
        }
    }, [updateFail, updateSuccess])


    const submitForm = (data) => {
        if (isSamePlan(data, getSeletedPayment(activeSubscription))) return;

        confirmationRef.current = () => {


            resetAndGoBack.current = () => {
                dispatch(resetUpdateStates());
                setModalVisible(false);
                history.goBack();
            }

            dispatch(updateSubscription({
                activeSubscription, newSubscription: data,
                isLoading: updateLoading,
            }))
        }
        setModalVisible(true);
    }
    // if (!data?.qnt_licenses || data?.qnt_licenses < 0) return;


    const getSeletedPayment = ({
        type_subscription, plan_subscription,
    }) => plans[type_subscription](plan_subscription);

    useEffect(() => {
        if (activeSubscription?.type_subscription) {
            setPaymentSelected(
                getSeletedPayment(activeSubscription)
            )
        }
        // eslint-disable-next-line
    }, [activeSubscription])

    return (
        <form onSubmit={handleSubmit(submitForm)}>
            <ModalElement
                show={isModalVisible}
                onClickYes={confirmationRef.current}
                onClickNo={() => setModalVisible(false)}
                title={localizedStrings.modalPaymentMethod}
                question={localizedStrings.modalPaymentMethodQuestion}
                answerYes={localizedStrings.answerYes}
                answerNo={localizedStrings.answerNo}
                type={"question"}
                hasLoadingAfterConfirmation
            />
            <ModalElement
                show={updateFail}
                title={localizedStrings.errorSubscription}
                subtitle={localizedStrings.errorUpdatingSubscription}
                onClose={resetAndGoBack.current}
                showErrors
                type={"error"}
            />
            <ModalElement
                show={updateSuccess}
                onShow={() => setModalVisible(false)}
                onClose={resetAndGoBack.current}
                title={localizedStrings.modalSuccessTitle}
                subtitle={localizedStrings.modalSuccessSubtitle}
                type={"success"}
            />
            <PaymentMethodSelector
                selectPaymentMethod={selectPaymentMethod}
                selectPlan={selectPlan}
                paymentSelected={paymentSelected}
                register={register}
                setValue={setValue}
                name={name}
                licenses={activeSubscription?.number_licenses}
                submitOnClick
            />
        </form>
    );
}
