
import { css } from 'styled-components';

const resetStyle = css`
html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    main, menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
      margin: 0;
padding: 0;
border: 0;
font-size: 100 %;
font: inherit;
vertical-align: baseline;
  }
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
  footer, header, hgroup, main, menu, nav, section {
  display: block;
}
  /* HTML5 hidden-attribute fix for newer browsers */
  * [hidden] {
  display: none;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote: before, blockquote: after,
  q: before, q: after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* http://www.paulirish.com/2012/box-sizing-border-box-ftw/ (2015/04/28)*/
html {
  box-sizing: border-box;
}
html, body {
  height: 100%
}
#root {
  height: inherit;
}

  *, *: before, *: after {
  box-sizing: inherit;
}
/* Additional resets */
a {
  text-decoration: none;
  color: inherit;
}
button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  text-align: inherit;
  outline: none;
  line-height: inherit;
  -webkit-appearance: none;
}
  /* Fix antialiasing */
  *, *: before, *: after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
  /* Disable user select on everything but texts */
  *, *: before, *: after {
  user-select: none;
}
p, h1, h2, h3, h4, h5, h6, blockquote, pre, ul, ol, li, table, tr, th, td, input, textarea {
  user-select: text;
}
/* SCROLLBAR */
::-webkit-scrollbar {
  width: 8px
}

::-webkit-scrollbar-thumb {
  background: #ababab60; 
}


.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  width: 3rem !important;
  padding: 6px !important;
  margin: 3px !important;
}

/* SPIN ANIMATION */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(720deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(720deg); }
}
/* MODAL */
.overlayModal{
  position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000080;
}
.ReactModal__Content--after-open{
  width: 400px;
}
@media screen and (max-width: 500px) {
  .ReactModal__Content--after-open{
    width: 300px !important;
  }

}
.react-datepicker__header{
  padding: 8px !important;
}
.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #686868;
  top: 10px;
}
.react-datepicker__navigation--previous {
  border-right-color: #686868;
  top: 10px;
}


select[readonly], input[readonly] {
  background: #eee;
  pointer-events: none;
  touch-action: none;
}
`

export {
  resetStyle,
}


