
import styled from 'styled-components';

const infoCardsStyle = styled.div(props => ({
    display:"grid",
    gridTemplateColumns: `repeat(${props.cards_length}, 1fr)`,
    justifyItems: 'center',
    alignItems:"center",
    justifyContent: "space-around",
}))


export {
    infoCardsStyle as InfoCardsStyle,
}
