import React from 'react';
import { ListColumn } from './style'
import { ReceiptItem, Text } from '../../../components'
import { localizedStrings } from '../../../constants/localizedStrings';
import { useSelector } from 'react-redux';
const width = window.innerWidth;
const isMobile = width < 860;
export default function ReceiptList({ receipts = [], ...options }) {
    const {
        company
    } = useSelector(state => state.users)

    return (
        <div>
            <ListColumn className={"row"} isMobile={width < 860}>
                <div className={`col-md-3 col-sm-3 ${isMobile ? "col-2" : "col-3"}`}>
                    <Text>
                        {localizedStrings.date}
                    </Text>
                </div>
                <div className={"col-md-3 col-sm-3 col-3"}>
                    <Text>
                        {localizedStrings.payment}
                    </Text>
                </div>
                <div className={`col-md-2 col-sm-2 ${isMobile ? "col-3" : "col-2"}`}>
                    <Text>
                        {localizedStrings.licencesQuantity}
                    </Text>
                </div>
                <div className={`col-md-2 col-sm-2 ${isMobile ? "col-3" : "col-2"}`}>
                    <Text>
                        {localizedStrings.price}
                    </Text>
                </div>
            </ListColumn>
            {
                Array.isArray(receipts) && receipts.map((receipt, index) => {
                    return (
                        <ReceiptItem
                            currency={company.currency}
                            key={index}
                            background={index % 2 === 0 ? "#fff" : "#F6F8FF"} {...receipt}
                        />
                    )
                })
            }
        </div>
    );
}
