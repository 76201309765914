
import styled from 'styled-components';

const stepContainer = styled.div(props => ({
    width: "100%",
    display: "flex",
    position: "relative",
    justifyContent: "center",
    ...props,
}))

const path = styled.div(props => ({
    position: "absolute",
    border: "1px solid #CAD5DD",
    top: "10px",
    width: props.width || "72%",
    ...props,
}))


export {
    stepContainer as StepContainer,
    path as Path,
}
