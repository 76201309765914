
import styled from 'styled-components';


const contentStyle = styled.div(props => ({
    width: "100%",
    "& > div:first-child": {
        borderBottom: props.hasSeparator ? "1px solid #cccccc" : "none",
        padding: "0px 0 24px 0"
    },
    "& > div:last-child": {
        padding: "24px 0 0 0"
    }
}))


export {
    contentStyle as ContentStyle,
}
