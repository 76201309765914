import React from 'react';
import { Text, EditButton } from '../../../components'
import { PaymentMethodInfoStyle, PaymentMethodDiv, LinksList } from './style'
import { localizedStrings } from '../../../constants/localizedStrings';
import { useSelector } from 'react-redux';
import { APP_PATH } from '../../../constants/environment';

export default function PaymentMethodInfo({ title, children, hasSeparator = false, type, cardNumber, cardType, history, ...options }) {
    const {
        activeSubscription,
        loadingActiveSubscription,
        company,
    } = useSelector(state => state.users)
    
    const goToEdit = (editItem) => {
        window.location.href = window.location.origin + APP_PATH + "/review/edit?edit=" + editItem
    }
    const hireMoreLicenses = () => {
        if(!activeSubscription.plan_subscription || !activeSubscription.type_subscription) {
            return window.HubSpotConversations.widget.open();
        }

        !loadingActiveSubscription && goToEdit("licenses")        
    };
    
    const blocked_no_payment = company.status === 'blocked_no_payment'
    
    const updateFormOfPayment = () => {
        if(!activeSubscription.plan_subscription || !activeSubscription.type_subscription) {
            return window.HubSpotConversations.widget.open();
        }

        !loadingActiveSubscription && goToEdit("payment")
    };

    const editBillingAddress = () => {
        if(!activeSubscription.plan_subscription || !activeSubscription.type_subscription) {
            return window.HubSpotConversations.widget.open();
        }

        !loadingActiveSubscription && goToEdit("address")
    };

    const editCard = () => !loadingActiveSubscription && goToEdit("card");

    const getTypeOfCard = () => {
        const cardTypes = ["visa", "mastercard"]
        if (cardTypes.includes(cardType)) return cardType;
    }
    
    return (
        <PaymentMethodInfoStyle {...options}>
            <PaymentMethodDiv>
                {}
                {
                    type === "card" &&
                    <>
                        {getTypeOfCard() && <img alt={localizedStrings.altCardType} src={"https://images.contelege.com.br/contratacao/" + getTypeOfCard() + ".png"} />}
                        <Text fontSize={"17px"} color={"#555555"}>
                            **** **** **** {cardNumber}
                        </Text>
                        <EditButton
                            marginLeft={"8px"}
                            onEdit={editCard}
                        />
                    </>
                }
                {
                    type === "boleto" &&
                    <>
                        <img alt={localizedStrings.altPaymentMethod} src="https://images.contelege.com.br/contratacao/boleto.png" />
                        <Text fontSize={"17px"} color={"#555555"}>{
                            localizedStrings.bankSlip}
                        </Text>
                    </>
                }
            </PaymentMethodDiv>
            <LinksList>
                <li>
                    {!blocked_no_payment && 
                    <Text as={"a"} onClick={hireMoreLicenses}>
                        {localizedStrings.hireMoreLicenses} >
                    </Text>
                    }
                </li>
                <li>
                    {!blocked_no_payment && 
                    <Text as={"a"} onClick={updateFormOfPayment}>
                        {localizedStrings.updateFormOfPayment} >
                    </Text>
                    }
                </li>
                <li>
                    <Text as={"a"} onClick={editBillingAddress}>
                        {localizedStrings.editBillingAddress} >
                    </Text>
                </li>
            </LinksList>
        </PaymentMethodInfoStyle>
    );
}
