const parseInputs = ({
    phone = "",
    cep = "",
    cardNumber = "",
    ...data
}, countryPhoneId = "") => {
    const newData = {
        ...data,
        // eslint-disable-next-line
        phone: countryPhoneId + phone.replace(countryPhoneId, "").replace(/[-()/ .]|(\ )/g, ""),
        // eslint-disable-next-line
        cep: cep.replace(/[-()/ .]|(\ )/g, ""),
        // eslint-disable-next-line
        cardNumber: cardNumber.replace(/[-()/ .]|(\ )/g, ""),
    }
    if (!data?.complement) newData.complement = "-"
    if (!data?.number) newData.number = "-"
    return newData;
};
const changeBorderColor = ({
    id = "",
    color = "#f00f0070",
    display
}) => {
    try {
        document.getElementById(id).style.borderColor = color;
        document.getElementById(`${id}-error`).style.display = display || 'block';
    } catch (error) {

    }
};
const clearInputsErrors = (inputs = []) => {
    inputs.map(inputId => changeBorderColor({ id: inputId, color: "#CBD5DD", display: 'none' }))
    return true;
}


export {
    parseInputs,
    changeBorderColor,
    clearInputsErrors
}