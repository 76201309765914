import React, { useEffect, useState, memo } from 'react';
import ModalQuestion from './modalQuestion/'
import ModalInfo from './modalInfo/'
import Modal from 'react-modal';
export default memo(function ModalElement({
    show, type, onClickNo, onClickYes, onClose, question, answerNo, answerYes,
    title, subtitle, description, showErrors = false, onShow, hasLoadingAfterConfirmation, redirectMessage,
    redirectButton
}) {

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: "0"
        }
    };

    Modal.setAppElement('#root')

    const [modalIsOpen, setIsOpen] = useState(show);

    function closeModal() {
        setIsOpen(false);
    }
    useEffect(() => {
        if (show) {
            onShow && onShow()
        }
        setIsOpen(show);
        // eslint-disable-next-line
    }, [show]);

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            overlayClassName="overlayModal"
            contentLabel="Example Modal"
            shouldCloseOnOverlayClick={false}
        >

            {
                type === "question" &&
                <ModalQuestion
                    title={title}
                    question={question}
                    answerNo={answerNo}
                    answerYes={answerYes}
                    onClose={() => {
                        onClose ? onClose() : onClickNo();
                    }}
                    onClickNo={onClickNo}
                    onClickYes={onClickYes}
                    hasLoadingAfterConfirmation={hasLoadingAfterConfirmation}
                />
            }
            {
                type === "error" &&
                <ModalInfo
                    title={title}
                    subtitle={subtitle}
                    description={description}
                    showErrors={showErrors}
                    onClose={() => {
                        closeModal();
                        onClose && onClose();
                    }}
                />
            }
            {
                type === "success" &&
                <ModalInfo
                    title={title}
                    subtitle={subtitle}
                    hasImage
                    urlImage={"https://images.contelege.com.br/contratacao/success.svg"}
                    onClose={() => {
                        closeModal();
                        onClose && onClose();
                    }}
                />
            }
            {
                type === "lock" &&
                <ModalInfo
                    title={title}
                    subtitle={subtitle}
                    redirectMessage={redirectMessage}
                    showErrors={showErrors}
                    redirectButton={redirectButton}
                />
            }
        </Modal>
    );
});