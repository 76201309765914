import React from 'react';
import { ButtonDefault, ButtonContainer } from './style'
import { LoadingSpin } from '../../components';
export default function SubmitButton({
    title, type = "submit", onClick, isLoading, withLoading = true,
    btnContainerOptions, isDisabled,
    ...options
}) {

    return (
        <div className={"row"} style={{ ...options }}>
            <ButtonContainer className={"col-md-12 col-sm-12 col-12"} {...btnContainerOptions} >
                <ButtonDefault type={type}
                    disabled={isDisabled || isLoading}
                    onClick={(e) => {
                        e.persist();
                        onClick && onClick();
                    }}>
                    {
                        withLoading &&
                        <LoadingSpin
                            show={isLoading}
                            position={"absolute"}
                            left={"10px"}
                            top={"7px"}
                            weight={"4px"}
                            halfColored
                            backColor={"#4d5585"}
                            frontColor={"#ffffff"}
                            size={"20px"}
                        />
                    }
                    {title}
                </ButtonDefault>
            </ButtonContainer>
        </div>
    );
}
