import React from 'react';
import { ContentBlock, LicensesInfo, PaymentMethodInfo, Text } from '../../components'
import { localizedStrings } from '../../constants/localizedStrings';
import { useSelector } from 'react-redux';
import { SYSTEM_URL } from '../../constants/environment';

export default function BillingData({ children, activeSubscription, history, ...options }) {
    const {
        company
    } = useSelector(state => state.users);

    const redirect = () => {
        return window.open(`${SYSTEM_URL}/#/user`, "_self")
    }

    return (
        <>
            <Text as={"a"} onClick={redirect} fontSize={"15px"} color={"#4596EC !important"} marginBottom={"14px"} fontWeight={"900"} cursor={"pointer"}>{localizedStrings.returnToSystem}</Text>
            <ContentBlock title={localizedStrings.billingData} hasSeparator className={"row"}>
                <LicensesInfo
                    currency={company.currency}
                    activeSubscription={activeSubscription}
                    dueAt={activeSubscription?.due_at}
                    className={"col-md-6 col-sm-12 col-12"}
                />
                <PaymentMethodInfo
                    type={activeSubscription?.type_subscription}
                    cardNumber={activeSubscription?.card_number}
                    history={history}
                    cardType={activeSubscription?.payment_company_code}
                    className={"col-md-6 col-sm-12 col-12"}
                />
            </ContentBlock>
        </>
    );
}
