import React from 'react';
import { InputDivStyle } from './style'
import { Text, Input } from '../../components'

export default function FieldBlock({ show = true, bottomComponent = false,
    visible, text, classIfVisible, textOptions, inputOptions, onChange, hasDefalutValue = true,
    classIfNotVisible, defaultClasses, placeholder, inputName, isRequired, customInput = false,
    register, getValues, textErrorOptions, textError, ...options
}, ref) {
    return (
        show &&
        <InputDivStyle className={(visible ? classIfVisible : classIfNotVisible) + " " + defaultClasses} {...options}>
            <Text {...textOptions} > {text} </Text>
            {
                customInput ? customInput() :
                    <>
                        <Input
                            {...inputOptions}
                            placeholder={placeholder} defaultValue={ hasDefalutValue && getValues()?.[inputName]}
                            autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                            ref={register({ name: inputName, required: isRequired })}
                            onChange={(event) => {
                                event.persist();
                                onChange && onChange(event)
                            }}
                        />
                        {textErrorOptions && <Text {...textErrorOptions} > {textError} </Text>}
                    </>
            }
            {bottomComponent && bottomComponent()}
        </InputDivStyle>
    );
}
