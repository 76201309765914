
import styled from 'styled-components';

const buttonDefault = styled.button(props => ({
    position: "relative",
    background: props.disabled ? "#4d5585" : "#1A2564",
    color: "#fff",
    fontFamily: "Effra light, Roboto,sans-serif",
    borderRadius: "2px",
    marginBottom: "5px",
    fontSize: props.isMobile ? "13px" : "15px",
    fontWeight: "bold",
    outline: "none",
    display: "flex",
    padding: props.isMobile ? "9px 9px" : "9px 35px",
    justifyContent: "center",
    alignSelf: props.alignSelf || "stretch",
    ...props
}))
const buttonContainer = styled.div(props => ({
    display: "flex",
    justifyContent: "flex-end",
    ...props
}))

export {
    buttonContainer as ButtonContainer,
    buttonDefault as ButtonDefault,
}
