import React from 'react';
import { ReturnStepStyle, } from './style'
import { Text } from '../../components'
import { localizedStrings } from '../../constants/localizedStrings';

export default function ReturnStep({ lastStep, goToPreviousStep, ...options }) {
    return (
        <ReturnStepStyle {...options}>
            <Text as={"a"} onClick={() => goToPreviousStep(-1)} fontSize={"20px"} textAlign="center">
                {`< ${localizedStrings.goBackTo} ${lastStep.name}`}
            </Text>
        </ReturnStepStyle>
    );
}
