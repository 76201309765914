const DEFAULT_RANGES_PRICING_SCHEMA = [
    {
        start_quantity: 0,
        end_quantity: 40,
        price: "59.90"
    },
    {
        start_quantity: 41,
        end_quantity: null,
        price: "44.90"
    }
];

const DISCOUNTS_BY_PLAN_TYPE = {
    "monthly": 0,
    "quarterly": 0,
    "semester": 0.10,
    "annual": 0.15
};

const getLicensesPriceByRange = ({
    licenses_number,
    pricing_schema
}) => {
    const hasValidPricingSchema = Array.isArray(pricing_schema) && pricing_schema.length > 0;

    const planPricingSchema = hasValidPricingSchema ? pricing_schema : DEFAULT_RANGES_PRICING_SCHEMA;

    const pricingSchemaByRange = planPricingSchema.find((schema, index) => {
        const {
            start_quantity: startQuantity,
            end_quantity: endQuantity
        } = schema;

        if(index === planPricingSchema.length - 1){
            const endQuantityIsNull = (endQuantity === null);

            return licenses_number >= startQuantity && endQuantityIsNull;
        };

        const licensesAreInRange = licenses_number >= startQuantity && licenses_number <= endQuantity;

        return licensesAreInRange;
    });

    return Number(pricingSchemaByRange.price);
};

const licensesPriceWithDiscount = ({
    payment_method,
    plan,
    raw_price
}) => {
    const isCreditCardPaymentMethod = payment_method === "card";

    const discountPercentage = isCreditCardPaymentMethod ? DISCOUNTS_BY_PLAN_TYPE["monthly"] : DISCOUNTS_BY_PLAN_TYPE[plan];

    const discountValuePerLicense = (raw_price * discountPercentage);

    const licensesWithDiscountPercentage = raw_price - discountValuePerLicense;

    return {
        price: licensesWithDiscountPercentage,
    };
};

const getPricePerLicense = ({ licenses, plan, type , pricing_schema}) => {
    const rawPrice = getLicensesPriceByRange({
        licenses_number: licenses,
        pricing_schema: pricing_schema
    });

    const {
        price,
    } = licensesPriceWithDiscount({
        payment_method: type,
        plan,
        raw_price: rawPrice
    });

    return {
        price,
        rawPrice
    };
};

export {
    getLicensesPriceByRange,
    getPricePerLicense
};
