
import styled from 'styled-components';

const InputDefault = styled.input(props => ({
  background: props.readOnly ? "#F3F3F3" : "#FFFFFF",
  border: "1px solid #E5E5E5",
  color: props.readOnly ? "#868E96" : "#000",
  boxSizing: "border-box",
  maxHeight: "32px",
  borderRadius: "4px",
  flex: "1",
  padding: "8px 16px",
  outline: 'none',
}))

export {
  InputDefault,
}
