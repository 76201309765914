
import styled from 'styled-components';

const footerStyle = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "12px",
    "& > p": {
        fontFamily: "Effra light, Roboto,sans-serif",
        fontSize: "15px",
        fontWeight: "900",
        color: "#999999",
        marginTop: "8px"
    }
}))



export {
    footerStyle as FooterStyle,
}
