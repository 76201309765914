
import styled from 'styled-components';


const infoItem = styled.div(props => ({
    display: "inline-flex",
    marginBottom: "12px",
    "& > p": {
        color: "#999999",
        fontSize: "15px",
        marginRight: "8px"
    },
    "& > span": {
        color: "#555555",
        fontSize: "15px"
    },
    "& > *": {
        fontFamily: "Effra light, Roboto,sans-serif",
        fontWeight: "900"
    }
}))
const licensesInfoStyle = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    marginBottom: "24px"
}))


export {
    infoItem as InfoItem,
    licensesInfoStyle as LicensesInfoStyle,
}
