
import styled from 'styled-components';


const editButtonStyle = styled.button(props => ({
    opacity: props.show ? "1" : "0",
    outline: "none",
    cursor: props.show ? "pointer" : "normal",
    background: "#fff",
    borderRadius: "5px",
    border: "1px solid #eee",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "30px",
    height: "30px",
    "& > img": {
        width: "15px",
        transform: "translateX(1.3px)"
    },
    ...props
}))


export {
    editButtonStyle as EditButtonStyle,
}
