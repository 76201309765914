
import styled from 'styled-components';

const listColumn = styled.div(props => ({
    padding: "0px 8px 18px 8px",
    "& > div": {
        display: props.isMobile ? "flex" : null,
        flex: props.isMobile ? "1" : null,
        alignItems: "center"
    },
    "& > div > p": {
        textAlign: "center",
        fontSize: "15px"
    },
    ...props,
}))


export {
    listColumn as ListColumn,

}
