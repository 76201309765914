import React from 'react';
import { ContentBlock, ReceiptList } from '../../components'
export default function Receipts({ children, receipts, ...options }) {
    return (
        <ContentBlock title={"Recibos"} hasSeparator>
            <ReceiptList
                receipts={receipts}
            />
        </ContentBlock>
    );
}
