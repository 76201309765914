import React, { useState } from 'react';
import { UserFieldsStyle, InputDivStyle } from './style'
import { Text, Input } from '../../../components'
import { localizedStrings } from '../../../constants/localizedStrings';
import MaskedInput from 'react-maskedinput'
import { useSelector } from 'react-redux';
import { EMAILS_DEFAULT_LENGTH, EMAIL_MAX_COUNT } from '../../../constants/environment'
export default function UserFields({ register, setValue, getValues }) {
    const onChangeInput = (name, val) => setValue(name, val);

    const {
        company,
    } = useSelector(state => state.users);
    const [ emailsMaxLength, setEmailsMaxLength ] = useState(EMAILS_DEFAULT_LENGTH)

    const getEmailsMaxLength = () => {
        const emails = getValues()?.emails;
        if (!emails) setEmailsMaxLength( EMAILS_DEFAULT_LENGTH );

        let allowed = emails?.length + EMAILS_DEFAULT_LENGTH;
        const exceededMaxLength = emails && (emails.split(",").length === EMAIL_MAX_COUNT);
        
        if (exceededMaxLength) allowed = emails?.length;
        setEmailsMaxLength( allowed );
    }
    return (
        <UserFieldsStyle>
            <div>
                <Text as={"label"} htmlFor={"document"} fontSize={"19px"}>
                    {localizedStrings.makeYourRegistrationBelow}
                </Text>
                <InputDivStyle>
                    <Text as={"label"} htmlFor={"document"} fontSize={"15px"} fontFamily={"Effra light,Roboto,sans-serif"}>
                        {localizedStrings.documentNumber}
                    </Text>
                    <MaskedInput mask="11.111.111/1111-11"
                        ref={register({ name: "document" })} value={getValues()?.document || company?.identification}
                        name="document" id={"document"} size="20" onChange={(event) => {
                            event.persist();
                            onChangeInput("document", event?.target?.value ?? "");
                        }} />
                    <Text as={"label"} id={'document-error'} display={'none'} paddingBottom={'6px'} color={"#f00f0070 !important"} fontSize={"13px !important"} fontFamily={"Effra light,Roboto,sans-serif"}> {localizedStrings.documentNumberError} </Text>
                    <Text as={"label"} color={"#AAAAAA"} fontSize={"15px"} fontFamily={"Effra light,Roboto,sans-serif"}> {localizedStrings.onlyNumbers} </Text>
                </InputDivStyle>
                <InputDivStyle>
                    <Text as={"label"} htmlFor={"phone"} fontSize={"15px"}>
                        {localizedStrings.enterYourPhone}
                    </Text>
                    <MaskedInput mask={localizedStrings.phoneMask} ref={register({ name: "phone" })} value={getValues()?.phone || ''}
                        name="phone" id={"phone"} size={localizedStrings.phoneMask.length} onChange={(event) => {
                            event.persist();
                            onChangeInput("phone", event?.target?.value ?? "");
                        }} />
                    <Text as={"label"} id={'phone-error'} display={'none'} color={"#f00f0070 !important"} fontSize={"13px !important"} fontFamily={"Effra light,Roboto,sans-serif"}> {localizedStrings.phoneError} </Text>
                </InputDivStyle>
                <InputDivStyle>
                    <Text as={"label"} htmlFor={"emails"} fontSize={"19px"} >
                        {localizedStrings.enterYourEmails}
                    </Text>
                    <Input
                        id={"emails"} name={"emails"} type={"number"} inputMask={"text"} maxLength={emailsMaxLength}
                        autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                        ref={register({ name: "emails" })} defaultValue={getValues()?.emails || company?.email}
                        onChange={(event) => {
                            event.persist();
                            getEmailsMaxLength();
                            onChangeInput("emails", event?.target?.value ?? "");
                        }}
                    />
                     <Text as={"label"} fontSize={"19px"} >
                        {localizedStrings.emailsLimit}
                    </Text>
                </InputDivStyle>

            </div>
        </UserFieldsStyle>
    );
}
