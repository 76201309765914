
import styled from 'styled-components';

const priceItem = styled.div(props => ({
    padding: "0px",
    display: "flex",
    margin: "20px 20px",
    height: "280px",
    borderRadius: "0 0 5px 5px",
    fontFamily: "Effra, Roboto,sans-serif",
    flexDirection: "column",
    "& > div": {
        width: "100%",
        border: "1.5px solid #E5E5E580",
        borderRadius: props.isMobile ? "12px" : null,
    },
    "& > div:first-child": {
        padding: "10px",
    },
    "& > div:last-child": {
        background: props.selected ? "#1A2564" : "#1A237A",
    },
    "& *": {
        cursor: "pointer"
    },
}))



const pricesList = styled.div(props => ({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    "& :hover > .i-want-this-plan": {
        transition: "0.2s",
        background: "#2633a5",
    },
    "& > div": {
        border: props.selected === 1 ? "1px solid #1A2564" : "0.5px solid #CBD5D090",
    },
    "& > div:first-child": {
        border: props.selected === 0 ? "1px solid #1A2564" : "0.5px solid #CBD5D090",
    },
    "& > div:last-child": {
        border: props.selected === 2 ? "1px solid #1A2564" : "0.5px solid #CBD5D090",
    },

}))
const planButton = styled.div(props => ({
    width: "100%",
    cursor: "pointer",
    textAlign: "center",
    fontWeight: "500",
    fontSize: "13px",
    outline: "none",
    border: "none",
    borderRadius:"0 0 5px 5px",
    padding: "10px",
    "& input": {
        display: "none"
    },
    background: "blue"

}))




export {
    planButton as PlanButton,
    pricesList as PricesList,
    priceItem as PriceItem,

}
