import { API_URL } from '../constants/environment';
import { getAccessTokenFromLocalStorage } from '../utils/getAccessToken';
import axios from 'axios';

const api = axios.create({
    baseURL: API_URL,
});

api.interceptors.request.use(
    async config => {
        const isInternalApi = config.url.search("billing") > 0;

        const token = getAccessTokenFromLocalStorage();
        
        if (token && isInternalApi) config.headers.Authorization = `Bearer ${token}`;

        return config;
    },
    err => Promise.reject(err)
);

export default api;
