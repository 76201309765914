
import styled from 'styled-components';

const textDefault = styled.p(props => ({
    color: props.color || "#1A2564",
    fontSize: props.fontSize || "22px",
    fontWeight: props.fontWeight || "bold",
    fontFamily: "Effra, Roboto,sans-serif",
    "& > a": {
        marginLeft: "4px",
        color: "#4596EC",
        fontFamily: "Effra light, Roboto,sans-serif",
        fontSize: "15px"
    },
    "& > span": {
        color: props.highlightSpan && "#1A2564",
    },
    ...props
}))

export {
    textDefault as TextDefault,
}
