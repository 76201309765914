import React, { useState, useEffect } from 'react';
import { QuantitySelector, InfoCards, PaymentMethodSelector } from '../../containers';
import { useSelector } from 'react-redux';
import { MINIMUM_LICENSES } from '../../constants/environment';
const ANNUAL_BOLETO_INDEX = 2;
const BOLETO_TYPE_INDEX = 0;
const CARD_TYPE_INDEX = 1;
export default function SelectPlanAndLicences({ register, setValue, getValues, toggleStepVisibility, name = "paymentSelected", goToNextStep, isNewSubscription }) {
    const {
        company
    } = useSelector(state => state.users);

    const isCountryNotBrazil = company?.country !== "BR";

    const {index, type} = {
        index: isCountryNotBrazil ? CARD_TYPE_INDEX : ANNUAL_BOLETO_INDEX,
        type: isCountryNotBrazil ? "card" : "boleto",
    }

    const [paymentSelected, setPaymentSelected] = useState(
        getValues()?.paymentSelected ??
        {
            index, value: { type }
        })
    
    setValue("paymentSelected", {value: paymentSelected?.value})

    const selectPaymentMethod = (planType, index) => {
        const isAlreadySelected = getValues()?.paymentSelected?.value?.type === planType?.value;
        const isFirstClick = getValues().paymentSelected === undefined && index === BOLETO_TYPE_INDEX;

        if (isAlreadySelected || isFirstClick) return;
        setValue(name, { value: { type: planType?.value } });
        setPaymentSelected({ value: { type: planType?.value } })
        toggleStepVisibility()
    }

    const selectPlan = (price, index) => {
        setValue(name, { index, ...price, });
        setPaymentSelected({ index, ...price })
        goToNextStep();
    }

    useEffect(() => {
        if(!getValues()?.qnt_licences) setValue("qnt_licences", company?.licenses ?? MINIMUM_LICENSES);
         // eslint-disable-next-line
    }, [company])

    return (
        <>
            <QuantitySelector
                register={register}
                setValue={setValue}
                getValues={getValues}
                />
            <InfoCards isNewSubscription={isNewSubscription} />
            <PaymentMethodSelector
                selectPaymentMethod={selectPaymentMethod}
                selectPlan={selectPlan}
                paymentSelected={paymentSelected}
                register={register}
                setValue={setValue}
                licenses={getValues()?.qnt_licences}
                name={name}
            />
        </>
    );
}
