import React from 'react';
import { InfoCardFixedPriceStyle, InfoCardStyle, BlueBackground, WhiteBackground } from './style'
import { Text } from '../../components'
import { localizedStrings } from '../../constants/localizedStrings';
import { formatMoney } from '../../utils/formatMoney';
export default function InfoCard({start_quantity, pricing_schema, price, pricePerEmployeer, price_schema_index }) {
    const mountPriceCard = () => {
        const has_valid_pricing_schema = Array.isArray(pricing_schema) && pricing_schema.length > 0;

        if(has_valid_pricing_schema === false) {
            return  (
                <Text> Error! </Text>
            );
        };

        const last_range_on_pricing_schema = (price_schema_index === pricing_schema.length - 1);
        const next_pricing_schema = last_range_on_pricing_schema ? null : pricing_schema[price_schema_index + 1];

        const has_next_pricing_schema = !!next_pricing_schema === true;

        const end_quantity = has_next_pricing_schema ? (next_pricing_schema.start_quantity - 1) : null;

        const not_is_last_pricing_range_and_has_next_pricing_range = last_range_on_pricing_schema === false && end_quantity !== null;

        const range_text_by_licenses_number = not_is_last_pricing_range_and_has_next_pricing_range === true ?
            `${start_quantity} ${localizedStrings.the} ${end_quantity}`
        :
            `${localizedStrings.above} ${start_quantity - 1}` 

        const licenses_range = `${range_text_by_licenses_number} ${localizedStrings.employees}`;

        return (
            <div>
                <WhiteBackground>
                    <Text>{licenses_range}</Text>
                </WhiteBackground>
                <BlueBackground>
                    <Text>{formatMoney(price || pricePerEmployeer)} {localizedStrings.perUser}</Text>
                </BlueBackground>
            </div>
        );
    };

    return (
        <>
        {pricing_schema?.length === 1 ?
            <InfoCardFixedPriceStyle className={"col-md-4 col-sm-12 col-12"}>
                {mountPriceCard()}
            </InfoCardFixedPriceStyle>
        :
            <InfoCardStyle className={"col-md-4 col-sm-12 col-12"}>
                {mountPriceCard()}
            </InfoCardStyle>
        } 
        </>
    );
}
