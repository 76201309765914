import React, { useEffect } from 'react';
import { Container, BillingData, Receipts } from '../../containers'
import { useDispatch, useSelector } from 'react-redux';
import { getReceipts, getUser } from '../../store/modules/';
import { AUTH_PATH } from '../../constants/environment';
import { getAccessTokenFromLocalStorage } from '../../utils/getAccessToken';
const width = window.innerWidth;
export default function ReviewSubscription({ history }) {
    const dispatch = useDispatch();
    const {
        activeSubscription, receipts, company, response
    } = useSelector(state => state.users);

    const loadUserReceipts = (clientId) => {
        dispatch(getReceipts({ clientId }))
    }

    const getUserData = async () => {
        const lastToken = getAccessTokenFromLocalStorage();
        await dispatch(getUser({
            lastToken,
            withRedirect: false
        }));
    }



    useEffect(() => {
        const token = getAccessTokenFromLocalStorage() ?? "";
        const hasToken = token && token.length !== 0;

        if (!hasToken) {
            window.location.href = window.location.origin + AUTH_PATH + "/" + window.location.search
        }

        if (company?.clientId) {
            loadUserReceipts(company.clientId)
        }
        // eslint-disable-next-line
    }, [company]);

    useEffect(() => {
        getUserData() // triggers getSubscription
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const has_pricing_schema = activeSubscription.hasOwnProperty('pricing_schema') && Array.isArray(activeSubscription.pricing_schema) || false;
        if (response && !has_pricing_schema) history.push('/new');
    }, [activeSubscription,response]);

    return (
        <Container
            className="col-md-12 col-sm-12 col-12" maxWidth={"650px"}
            padding={width < 860 ? "32px 12px 32px 12px" : "32px 0 32px 0"}>
            <BillingData
                history={history}
                activeSubscription={activeSubscription}
            />
            <Receipts
                receipts={receipts}
            />
        </Container>
    );
}
