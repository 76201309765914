import React from 'react';
import { localizedStrings } from '../../constants/localizedStrings';
import {
    SelectPlanAndLicences, RegisterSubscription,
    RegisterCreditCard, ConfirmSubscription
} from '../../containers'

export default function NewSubscriptionInputs({
    register,
    setValue,
    getValues,
    currentPage,
    goToNextStep,
    goToPreviousStep,
    toggleStepVisibility,
}, ) {
  
    return (
        <>
            {
                currentPage?.name === localizedStrings.licenses &&
                <SelectPlanAndLicences
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    goToNextStep={goToNextStep}
                    toggleStepVisibility={toggleStepVisibility}
                    isNewSubscription
                />
            }

            {
                currentPage?.name === localizedStrings.creditCard &&
                <RegisterCreditCard
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    goToPreviousStep={goToPreviousStep}
                    goToNextStep={goToNextStep}
                />
            }
            {
                currentPage?.name === localizedStrings.registations &&
                <RegisterSubscription
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    goToPreviousStep={goToPreviousStep}
                    goToNextStep={goToNextStep}
                />
            }
            {
                currentPage?.name === localizedStrings.confimation &&
                <ConfirmSubscription
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    goToPreviousStep={goToPreviousStep}
                    goToNextStep={goToNextStep}
                />
            }
        </>
    );
}