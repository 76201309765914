import { css } from 'styled-components';

const fonts = css`
/* ADD 1 FONT PER LINE */
@font-face{
    font-family: "Effra";
    src: url('https://contelege.com.br/fonts/Effra_Std_Rg.WOFF');
  }
  @font-face{
    font-family: "Effra light";
    src: url('https://contelege.com.br/fonts/Effra_Std_Lt.WOFF');
  }
  @font-face{
    font-family: "Roboto";
    src: url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
  }
`;

export {
    fonts
};