async function SendMessageSlack({
  channelName,
  text,
  ...other
}) {
  const slack_url = "https://hooks.slack.com/services/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";

  const data = {
    channel: "#" + channelName,
    text,
    ...other,
  }

  try {
    const response =  await fetch(slack_url, {
      method: 'POST',
      body: JSON.stringify(data)
    });

    if(response.status < 200 || response.status > 299) throw new Error('Error send message to slack');

  } catch (error) {
    console.error(error);
  }

}



export const sendErroSlackAssociateConsultant = async ({
  clientId,
  consultantEmail,
  error
}) => {
  const channelName = "logs-erros-contratação";

  const text = "``` " + `Error to inclunde consultant email: ${consultantEmail} in clientId: ${clientId}. 
  originURL: ${window.location.hostname}.
  ` +JSON.stringify({  erro: error }, null, " ") + "```";

  await SendMessageSlack({ channelName, text });

}