import React, { useEffect } from 'react';
import { UserFields, AddressFields } from '../../containers';
import { localizedStrings } from '../../constants/localizedStrings';
import { useSelector } from 'react-redux';
import { changeBorderColor, clearInputsErrors } from '../../utils/inputs';
import { validateCnpj } from '../../utils/cnpj';
export default function RegisterSubscription({ register, setValue, getValues, goToNextStep, goToPreviousStep, edittion = false, isLoading, isDisabled }) {
    const {
        company,
        activeSubscription
    } = useSelector(state => state.users);
    const [countryPhoneId] = localizedStrings.phoneMask?.split(" ");
    // eslint-disable-next-line
    const parseField = (val = "") => val.replace(countryPhoneId, "").replace(/[-()_/ .]|(\ )/g, "")
    const validField = (val = "", mask) => parseField(val).length === mask.length;
    const verifyFields = () => {
        const { document, phone, cep, } = getValues();
        let errors = []

        const hasValidPhone = validField(phone, localizedStrings.rawPhoneMask);
        if (!hasValidPhone) {
            errors.push("phone")
        } else {
            clearInputsErrors(["phone"]);
        }

        const hasValidDocument = validField(document, localizedStrings.rawDocumentMask) && validateCnpj(document)
        if (!hasValidDocument) {
            errors.push("document")
        } else {
            clearInputsErrors(["document"]);
        }

        const hasValidZipCode = validField(cep, localizedStrings.rawZipCodeMask) || edittion;

        if (!hasValidZipCode) {
            errors.push("cep")
        } else {
            clearInputsErrors(["cep"]);
        }

        errors.map(id => changeBorderColor({ id }))
        if (hasValidPhone && hasValidDocument && hasValidZipCode) return goToNextStep(1);

        return false;
    };

    useEffect(() => {
        if (!edittion) {
            !getValues()?.document && setValue("document", company?.identification);
            !getValues()?.emails && setValue("emails", company?.email);
            !getValues()?.cep && setValue("cep", company?.address?.cep || company?.address?.zipcode);
        }
        // eslint-disable-next-line
    }, [company]);

    return (
        <>
            <UserFields
                countryPhoneId={countryPhoneId}
                register={register}
                setValue={setValue}
                getValues={getValues}
            />
            <AddressFields
                register={register}
                setValue={setValue}
                getValues={getValues}
                parseField={parseField}
                goToNextStep={verifyFields}
                previousValues={activeSubscription}
                edittion={edittion}
                isDisabled={isDisabled}
                isLoading={isLoading}
            />

        </>
    );
}
