import React, { memo } from 'react';
import { ContentStyle } from './style'
import { Text } from '../../components'
export default memo(function ContentBlock({ title, children, hasSeparator = false, ...options }) {
    return (
        <ContentStyle hasSeparator={hasSeparator}>
            <div>
                <Text>{title}</Text>
            </div>
            <div {...options}>
                {children}
            </div>
        </ContentStyle>
    );
});