
import {
    UPDATE_STEPS, FETCH_ADDRESS_SUCCESS,
    RESET_UPDATE_STATES,
    UPDATE_UPDATE_STATES,
    RESET_ADDRESS_STATES,
    UPDATE_ADDRESS_STATES,
    RESET_CREATE_STATES,
    UPDATE_CREATE_STATES,
} from './reducer';
import api from '../../../services/api'
import { getCardType } from '../../../utils/creditcard'
import { format } from 'date-fns';
import { localizedStrings } from '../../../constants/localizedStrings';
import qs from 'qs';
import { APP_PATH } from '../../../constants/environment';
import { WEB_API_URL } from '../../../constants/environment';
import axios from 'axios';
import { getAccessTokenFromLocalStorage } from '../../../utils/getAccessToken';
import { sendErroSlackAssociateConsultant } from '../../../utils/sendMessageErrorToSlack';

export function resetUpdateStates() {
    return {
        type: RESET_UPDATE_STATES,
    };
}

export function updateUpdateStates({ updateSuccess = false, updateFail = false, updateLoading = false }, errors = [], limitExceeded = false) {
    return {
        type: UPDATE_UPDATE_STATES,
        payload: {
            updateSuccess,
            updateFail,
            updateLoading,
            updateErrors: errors,
            limitExceeded,
        }
    };
}
export function resetAddressStates() {
    return {
        type: RESET_ADDRESS_STATES,
    };
}
export function updateAddressStates({ addressSuccess = false, addressFail = false, addressLoading = false }) {
    return {
        type: UPDATE_ADDRESS_STATES,
        payload: {
            addressSuccess,
            addressFail,
            addressLoading
        }
    };
}
export function resetCreateStates() {
    return {
        type: RESET_CREATE_STATES,
    };
}
export function updateCreateStates({ createSuccess = false, createFail = false, createLoading = false }, errors = []) {
    return {
        type: UPDATE_CREATE_STATES,
        payload: {
            createSuccess,
            createFail,
            createLoading,
            createErrors: errors,
        }
    };
}
export function fetchAddressSuccess(address) {
    return {
        type: FETCH_ADDRESS_SUCCESS,
        payload: {
            address
        }
    };
}



export function updateSteps({ steps, currentStepIndex }) {
    return {
        type: UPDATE_STEPS,
        payload: {
            steps,
            currentStepIndex
        }
    };
}
export const goToStep = data => async dispatch => {
    const { currentStepIndex, steps, step: stepCount } = data;

    let notVisiblStep = null;
    let newSteps = JSON.parse(JSON.stringify(steps))
        .filter((step, index) => {
            if (!step.visible) notVisiblStep = { ...step, index } //remove not visible steps
            return step.visible
        });

    const stepNotFound = (stepCount + currentStepIndex) > newSteps.length || (stepCount + currentStepIndex) < 0;

    if (stepNotFound) return;

    newSteps = newSteps.map((step, index) => {
        if (index === (currentStepIndex + stepCount)) {
            return { ...step, selected: true }
        }
        return step;
    });


    if (stepCount < 0) {
        newSteps = newSteps.map((newStep, index) => {
            let isSelected = false
            if (index <= currentStepIndex + stepCount) isSelected = true;
            return { ...newStep, selected: isSelected }
        })
    }

    if (notVisiblStep?.index) {
        newSteps.splice(notVisiblStep.index, 0, notVisiblStep); //add again not visible steps
        delete notVisiblStep.index;
    }

    dispatch(updateSteps({
        steps: newSteps,
        currentStepIndex: currentStepIndex + stepCount,
    }));
};

export const toggleStep = data => async dispatch => {
    const { steps, name } = data;
    const newSteps = steps
        .map(step => {
            if (step.name === name) return { ...step, visible: !step.visible }
            return step
        })
    dispatch(updateSteps({
        steps: newSteps,
    }));
};

const parseErrors = (errorList = []) => {
    const newErrorList = [];
    const knownErrors = ["registry_code", "phones", "card_number", "holder_name", "payment_company_code"]
    const errorsTypes = {
        registry_code: () => newErrorList.push(localizedStrings.errorIdentification),
        phones: () => newErrorList.push(localizedStrings.errorPhone),
        card_number: () => newErrorList.push(localizedStrings.errorCard),
        holder_name: () => newErrorList.push(localizedStrings.errorHolderName),
        payment_company_code: () => newErrorList.push(localizedStrings.errorPaymentCompanyCode),
    }

    errorList.map(async (error) => {
        console.log(error);

        if (knownErrors.includes(error.parameter)) errorsTypes[error.parameter]();

        logErrors(error);    
        return error;
    })
    if(newErrorList.length > 0 ) logErrors(newErrorList);

    return newErrorList
}

export const searchAddreessFromZipCode = data => async dispatch => {
    dispatch(resetAddressStates())
    dispatch(updateAddressStates({ addressLoading: true }))
    const { zipCode } = data;
    const errorFlag = "<error>"
    const URLs = [
        `https://viacep.com.br/ws/${zipCode}/json/`,
        `https://apps.widenet.com.br/busca-cep/api/cep/${zipCode}.json`
    ];

    const addressParser = (rawAddress, index) => {
        const parsedAddress = {
            address: null,
            neighborhood: null,
            city: null,
            state: null
        }
        if (index === 0) {
            parsedAddress.address = rawAddress?.logradouro;
            parsedAddress.neighborhood = rawAddress?.bairro;
            parsedAddress.city = rawAddress?.localidade;
            parsedAddress.state = rawAddress?.uf;
        }
        if (index === 1) {
            parsedAddress.address = rawAddress?.address;
            parsedAddress.neighborhood = rawAddress?.district;
            parsedAddress.city = rawAddress?.city;
            parsedAddress.state = rawAddress?.state;
        }
        if (index !== 1 && index !== 0) {
            dispatch(updateAddressStates({ addressFail: true }))
            return;
        }
        dispatch(updateAddressStates({ addressSuccess: true }))
        dispatch(fetchAddressSuccess(parsedAddress))
        return;
    }



    const getAddress = async (url = false, index) => {
        try {
            if (!url) throw errorFlag;
            await api.get(url)
                .then(result => {
                    const resultHasError = !result || (result?.data?.status === 404 || result?.data?.status === 400);
                    if (result?.data?.erro || resultHasError) {
                        if(index < URLs.length){
                            const newIndex = index + 1;
                            return getAddress(URLs[newIndex], newIndex);
                        }
                        throw new Error('CEP not found');
                    }
                    addressParser(result?.data ?? {}, index)
                })
        } catch (error) {
            dispatch(updateAddressStates({ addressFail: true }));
            dispatch(fetchAddressSuccess({}))
        }
    }
    getAddress(URLs[0], 0);

};

const logErrors = async (error) => {
    try {
        const URL = "/billing/log-errors";
        await api.post(URL, error);
        return;
    } catch (error) {
        console.log(error);
    }
}

const isValidCreditCard = async data => {
    let isValid = false;
    try {
        const URL = "/billing/v1/payment_methods/ge/" + data?.clientId;
        const {
            data: { content }
        } = await api.get(URL);
        isValid = !!content.filter(card => card.code === data.creditCard).length;
    } catch (error) {
        console.log(error);
    }
    return isValid
};

export const createSubscription = data => async dispatch => {
    const URL = "/billing/v1/ge/";
    if (data.isLoading) return;
    dispatch(resetCreateStates())
    dispatch(updateCreateStates({ createLoading: true }))
    const params = {
        client_id: data?.client_id,
        product: "ge",
        number_licenses: data?.qnt_licences,
        type_subscription: data?.paymentSelected?.value?.type,
        holder_name: data?.cardName,
        card_expiration: data?.expirationDate,
        card_number: data?.cardNumber,
        card_cvv: data?.securityCode,
        payment_company_code: getCardType(data?.cardNumber),
        plan_subscription: data?.paymentSelected?.value?.plan,
        due_at: data?.dueDate ?? format(new Date(), "yyyy-MM-dd", new Date()),
        corporateName: data?.corporateName,
        identification: data?.document,
        phone: data?.phone,
        email_copy: data?.emails || "-",
        zipcode: data?.cep,
        address: data?.address || "-",
        complement: data?.complement || "-",
        number: data?.addressNumber || "-",
        neighborhood: data?.neighborhood || "-",
        city: data?.city || "-",
        state: data?.state || "-",
        country: data?.country ?? localizedStrings.country,
        franchisee: data?.consultant?.name ?? "consultor_nao_encontrado_00"
    };

    const hasCardNumber = !!params.payment_company_code.length;

    if (hasCardNumber) {

        const isCardValid = await isValidCreditCard({
            clientId: params.client_id,
            creditCard: params.payment_company_code
        });
        
        if (!isCardValid) return dispatch(updateCreateStates({ createFail: true }, parseErrors([{ parameter: "payment_company_code" }])));

    }

    const addEmailFromConsultantInClient = async ({ clientId, consultantEmail }) => {
       try{
            const token = getAccessTokenFromLocalStorage() ?? "";

            await axios.put(`${WEB_API_URL}/api/clients/${clientId}`, 
                { consultantUser: consultantEmail },
                { headers: { Authorization: `Bearer ${token}` } }
            );

       }catch(error) {
        await sendErroSlackAssociateConsultant({ consultantEmail, clientId, error});
       }
    }

    await api.post(URL, qs.stringify(params))
        .then( async  result => {
            if (result) {

                const selectedConsultantEmail =  data?.consultant?.email;
               
                if(selectedConsultantEmail) {  
                    await addEmailFromConsultantInClient({ clientId: params.client_id, consultantEmail: selectedConsultantEmail });
                }

                if (params?.type_subscription) {
                    window.location.href = window.location.origin + APP_PATH + "/success?type=" + params?.type_subscription;
                } else {
                    window.location.href = window.location.origin + APP_PATH + "/review";
                }
                dispatch(updateCreateStates({ createSuccess: true }));
                return;
            }
            dispatch(updateCreateStates({ createFail: true }));
        })
        .catch(error => {
            const errors = error?.response?.data?.detail?.errors;
            dispatch(updateCreateStates({ createFail: true }, parseErrors(errors)));
            return;
        })
};

export const updateSubscription = data => async dispatch => {
    const { activeSubscription, newSubscription, isLoading } = data;
    if (isLoading) return;
    dispatch(updateUpdateStates({ updateLoading: true }));

    const URL = "/billing/v1/ge/" + activeSubscription?.client_id;
    let cardNumber = newSubscription?.cardNumber || activeSubscription?.card_number;
    if (cardNumber?.length < 7) {
        cardNumber = null; // vindi throws error if card number is less than 6
    }
    const [licenses, totalHiredLicences] = [+data?.previousLicences, +newSubscription?.qnt_licences];
    if (licenses && totalHiredLicences) {
        let licencesDifference = 0;
        const isLicenceUpgrade = licenses < totalHiredLicences;
        if (isLicenceUpgrade) licencesDifference = totalHiredLicences - licenses;
        newSubscription.qnt_licences = licencesDifference
    }
    const params = {
        client_id: activeSubscription?.client_id,
        product: "ge",
        number_licenses: newSubscription?.qnt_licences,
        type_subscription: newSubscription?.paymentSelected?.value?.type || activeSubscription?.type_subscription,
        holder_name: newSubscription?.cardName,
        card_expiration: newSubscription?.expirationDate,
        card_number: cardNumber,
        card_cvv: newSubscription?.securityCode,
        payment_company_code: getCardType(newSubscription?.cardNumber),
        plan_subscription: newSubscription?.paymentSelected?.value?.plan || activeSubscription?.plan_subscription,
        due_at: format(new Date(), "yyyy-MM-dd", new Date()),
        corporateName: newSubscription?.corporateName,
        identification: newSubscription?.document || activeSubscription?.identification,
        phone: newSubscription?.phone,
        email_copy: newSubscription?.emails,
        zipcode: newSubscription?.cep,
        address: newSubscription?.address,
        complement: newSubscription?.complement,
        number: newSubscription?.addressNumber,
        neighborhood: newSubscription?.neighborhood,
        city: newSubscription?.city,
        state: newSubscription?.state || activeSubscription?.state || "-",
        country: activeSubscription?.country,
        franchisee: newSubscription?.consultant?.name ?? "consultor_nao_encontrado_01"
    };

    const hasCardNumber = !!params.payment_company_code.length;

    if (hasCardNumber) {

        const isCardValid = await isValidCreditCard({
            clientId: params.client_id,
            creditCard: params.payment_company_code
        });

        if (!isCardValid) return dispatch(updateUpdateStates({ createFail: true }, parseErrors([{ parameter: "payment_company_code" }])));

    }

    await api.put(URL, qs.stringify(params))
        .then(result => {
            if (result) {
                dispatch(updateUpdateStates({ updateSuccess: true }));
                return;
            }
            dispatch(updateUpdateStates({ updateFail: true }));
        })
        .catch(error => {
            const limitExceededIdentifier = "limit of charge to change payment method";
            let limitExceeded = false;
            const errors = error?.response?.data?.detail?.errors;
            if (error?.response?.data?.errMsg === limitExceededIdentifier) limitExceeded = true;

            dispatch(updateUpdateStates({ updateFail: true }, parseErrors(errors), limitExceeded));
        })
};

