import React, { useRef, useEffect } from 'react';
import {
    InfoSummary, QuantitySelector, InfoCards
} from '../../../containers'
import {
    SubmitButton, ModalElement
} from '../../../components'
import { useForm } from 'react-hook-form'
import { localizedStrings } from '../../../constants/localizedStrings';
import { useDispatch, useSelector } from 'react-redux';
import { updateSubscription, resetUpdateStates } from '../../../store/modules/';
import { applyLicensesDiscount } from '../../../store/modules';
import { APP_PATH } from '../../../constants/environment';
import { getLicensesPriceByRange } from '../../../utils/pricePerLicense';
export default function EditLicenses({
    history,
}) {
    const { register, setValue, handleSubmit, getValues } = useForm();
    const resetAndGoBack = useRef(null);
    const {
        activeSubscription
    } = useSelector(state => state.users);
    const {
        updateSuccess, updateFail, updateLoading,
    } = useSelector(state => state.subscription);

    const dispatch = useDispatch();
    const submitForm = (data) => {
        resetAndGoBack.current = () => {
            dispatch(resetUpdateStates());
            goBackToReview();
        }
        dispatch(updateSubscription({
            activeSubscription, newSubscription: data,
            isLoading: updateLoading,
            previousLicences: activeSubscription?.number_licenses,
        }))
    }

    const goBackToReview = () => {
        window.location.href = window.location.origin + APP_PATH + "/review";
    }

    const getSeletedPlan = ({
        type_subscription, plan_subscription,
        price_per_license, number_licenses, ...activeSubscription
    }) => {
        if ( !type_subscription ) return;
        if ( activeSubscription?.pricing_schema.length === 1 ) price_per_license = activeSubscription?.pricing_schema[0]?.price;
        
        if (activeSubscription?.pricing_schema.length > 1 ) {
            price_per_license = getLicensesPriceByRange({
                licenses_number: number_licenses,
                pricing_schema: activeSubscription?.pricing_schema
            });
        }
        
        const selectedPlan = {
            value: {
                type: type_subscription,
                value: plan_subscription
            },
            name: localizedStrings?.[plan_subscription],
            price: Number(price_per_license),
            pricing_schema: activeSubscription.pricing_schema,
            licencesQuantity: Number(number_licenses),
        }

        return selectedPlan;
    }

    useEffect(() => {
        if (!getValues().qnt_licences) {
            setValue("qnt_licences", 0);
            dispatch(applyLicensesDiscount({
                licenses: activeSubscription?.number_licenses,
                plan: activeSubscription?.plan_subscription,
                type: activeSubscription?.type_subscription
            }))
        }
        // eslint-disable-next-line
    }, [activeSubscription])

    return (
        <form onSubmit={handleSubmit(submitForm)}>
            <ModalElement
                show={updateFail}
                onClose={resetAndGoBack.current}
                title={localizedStrings.errorSubscription}
                subtitle={localizedStrings.errorUpdatingSubscription}
                showErrors
                type={"error"}
            />
            <ModalElement
                show={updateSuccess}
                onClose={resetAndGoBack.current}
                title={localizedStrings.modalSuccessTitle}
                subtitle={localizedStrings.modalSuccessSubtitle}
                type={"success"}
            />
            <QuantitySelector
                register={register}
                setValue={setValue}
                getValues={getValues}
                isEdit={true}
            />
            <InfoCards />
            <InfoSummary
                maxWidth={"100%"}
                getValues={getValues}
                setValue={setValue}
                register={register}
                summaryReadOnly={true}
                selectedPlan={getSeletedPlan(activeSubscription)}
            />
            <SubmitButton
                isLoading={updateLoading}
                title={localizedStrings.updateLicenses}
            />
        </form>
    );
}
