
import styled from 'styled-components';

const loadingContainer = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    ...props
}))
const messageContainer = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    ...props
}))

const loadingWrapper = styled.div(props => ({
    width: "100%",
    ...props
}))
const loadingSpin = styled.div`
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #3498db;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;`


export {
    loadingSpin as LoadingSpin,
    loadingContainer as LoadingContainer,
    messageContainer as MessageContainer,
    loadingWrapper as LoadingWrapper,
}
