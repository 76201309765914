
import styled from 'styled-components';

const consultantSelectorStyle = styled.div(props => ({
    display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column",
    padding: props.isMobile ? "30px auto" : "17px 0 ",
}))
const consultantSelectorTitleDiv = styled.div(props => ({
    maxWidth: "500px",
    width: "100%", padding: props.isMobile ? "auto" : "0px",
    "& > p": {
        paddingBottom: props.isMobile ? "12px" : "0",
    }
}))
const consultantSelectorRow = styled.div(props => ({
    maxWidth: "500px",
    width: "100%",
    padding: props.isMobile ? "12px auto" : "12px 0 ",
    "& > div": {
        padding: "0px",
    }
}))
const buttonRow = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    maxWidth: "500px",
    width: "100%",
    padding: props.isMobile ? "12px auto" : "0",
    flexDirection: "column",
    "& > div": {
        marginTop: "12px",
    },
}))
const buttonSubmitStyle = styled.button(props => ({
    position: "relative",
    background: "#67AC5A",
    color: "#fff",
    flex: "1",
    padding: "12px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
}))

export {
    buttonSubmitStyle as ButtonSubmitStyle,
    buttonRow as ButtonRow,
    consultantSelectorRow as ConsultantSelectorRow,
    consultantSelectorTitleDiv as ConsultantSelectorTitleDiv,
    consultantSelectorStyle as ConsultantSelectorStyle
}