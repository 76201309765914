const placeThousandSeparator = money => {
    try {
        const thousandDigits = 3; // 1.000
        const [
            beforeComma,
            afterComma
        ] = money.split(",");
        const reversedSignificantNumber = beforeComma.split("").reverse();
        if (reversedSignificantNumber.length <= thousandDigits) return money;
        reversedSignificantNumber.splice(3, 0, ".")
        reversedSignificantNumber.reverse()

        const formattedMoney = reversedSignificantNumber.join("") + "," + afterComma;
        return formattedMoney;
    } catch (error) {
        console.log(error);
        return money;
    }
}
export const formatMoney = money => {
    const rawMoney = money
    try {
        money = +money;
        money = money.toFixed(2);
        money = money.toString().replace(".", ",");
        money = placeThousandSeparator(money)
        return money
    } catch (error) {
        console.log(error);
        return rawMoney;
    }
}