import React from 'react';
import { Text } from '../../../components'
import { SuccessImageStyle, } from './style';
import { localizedStrings } from '../../../constants/localizedStrings';
const width = window.innerWidth;
const isMobile = width < 860;

export default function SuccessImage({ ...options }) {
    return (
        <SuccessImageStyle className={"col-md-12 col-sm-12 col-12"} isMobile={isMobile}>
            <Text>
                {localizedStrings.orderHasBeenPlaced}
            </Text>
            <img alt={localizedStrings.altSuccess} src={"https://images.contelege.com.br/contratacao/success-image.svg"} />
        </SuccessImageStyle>
    );
}
