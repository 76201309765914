
import styled from 'styled-components';

const registerCardBtnStyle = styled.div(props => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0 ",
    "& > div": {
        maxWidth: "500px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    "& > div > button": {
        background: "#1A2564",
        color: "#fff",
        fontFamily: "Effra light, Roboto,sans-serif",
        borderRadius: "2px",
        marginBottom: "5px",
        fontSize: props.isMobile ? "13px" : "15px",
        fontWeight: "bold",
        outline: "none",
        display: "flex",
        padding: props.isMobile ? "9px 9px" : "9px 13px",
        justifyContent: "center",
        alignSelf: props.alignSelf || "flex-end",
    },
}))
const inputDivStyle = styled.div(props => ({
    display: "flex",
    justifyContent: props.justifyContent || "center",
    alignItems: props.alignItems || "center",
    flexDirection: "row",
    padding: props.padding || "0 0 10px 0",
    width: props.width || "100%",
    "& > p": {
        color: "#AAAAAA",
        fontFamily: "Effra light, Roboto,sans-serif",
        fontSize: "15px",
        margin: "0 0 0 10px",
    },
    "& > img": {
        width: "15px",
    },

    ...props
}))



export {
    inputDivStyle as InputDivStyle,
    registerCardBtnStyle as RegisterCardBtnStyle,

}
