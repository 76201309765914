import React, { useEffect } from 'react';
import { PaymentMethodSelectorStyle } from './style'
import { Text, HorizontalPaymentMethodSelector, HorizontalPriceList } from '../../components'
import { useSelector, useDispatch } from 'react-redux';
import { localizedStrings } from '../../constants/localizedStrings';
import { applyLicensesDiscount } from '../../store/modules';
import { MINIMUM_LICENSES } from '../../constants/environment';

export default function PaymentMethodSelector({ register, name, licenses, selectPaymentMethod, selectPlan, paymentSelected, submitOnClick }, ) {
    const {
        prices, planTypes
    } = useSelector(state => state.prices);
    const {
        activeSubscription
    } = useSelector(state => state.users);

    const dispatch = useDispatch();

    const normalizeDiscount = () => {
        dispatch(applyLicensesDiscount({
            licenses: licenses ?? MINIMUM_LICENSES
        }))
    }

    useEffect(() => {
        normalizeDiscount();
        // eslint-disable-next-line
    }, [activeSubscription]);

    return (
        <PaymentMethodSelectorStyle>
            <Text>
                {localizedStrings.paymentMethodQuestion}
            </Text>
            <HorizontalPaymentMethodSelector
                planTypes={planTypes}
                selectPaymentMethod={selectPaymentMethod}
                paymentSelected={paymentSelected}
            />
            <HorizontalPriceList
                paymentSelected={paymentSelected}
                prices={prices}
                selectPlan={selectPlan}
                licenses={licenses}
                register={register}
                name={name}
                submitOnClick={submitOnClick}
            />
        </PaymentMethodSelectorStyle>
    );
}