import React, { useState } from 'react';
import { Text } from '../../../components'
import { SuccessDescriptionStyle, ButtonStyle } from './style';
import { localizedStrings } from '../../../constants/localizedStrings';
import { useSelector } from 'react-redux';
import { getUrlParam } from '../../../utils/params';
import { LOGIN_URL, APP_PATH } from '../../../constants/environment';
const width = window.innerWidth;
const isMobile = width < 860;
const reviewPath = window.location.origin + APP_PATH + "/review";
export default function SuccessDescription({ ...options }) {
    const {
        planTypes
    } = useSelector(state => state.prices);

    const getSubscriptionType = () => {
        const urlValue = getUrlParam("type");
        const [subscriptionPlan] = planTypes.filter(plan => plan.value === urlValue)
        if (!subscriptionPlan) window.location.href = reviewPath;
        return subscriptionPlan
    }

    const [subscriptionType] = useState(getSubscriptionType());

    return (
        <SuccessDescriptionStyle className={"col-md-12 col-sm-12 col-12"} isMobile={isMobile}>
            <Text color={"#1a2565"} fontSize={"17px"} fontWeight={"bold"} fontFamily={"effra, sans-serif "}>
                {
                    subscriptionType.value === "boleto" &&
                    localizedStrings.orderHasBeenPlacedBankSlipTitle
                }
                {
                    subscriptionType.value === "card" &&
                    localizedStrings.orderHasBeenPlacedCreditCardTitle
                }
            </Text>
            <div>
                <Text color={"#767676"} fontSize={"15px"} fontFamily={"Effra light, sans-serif "}>
                    {localizedStrings.orderHasBeenPlacedDescription1}
                    <Text as={"a"} href={reviewPath}>
                        {localizedStrings.orderHasBeenPlacedDescription2}
                    </Text>
                    {localizedStrings.orderHasBeenPlacedDescription3}
                    <Text as={"a"} href={"mailto:" + localizedStrings.orderHasBeenPlacedEmail}>
                        {localizedStrings.orderHasBeenPlacedEmail}.
                    </Text>
                </Text>
            </div>
            <Text color={"#767676"} fontSize={"17px"} fontWeight={"bold"} fontFamily={"effra, sans-serif "} marginBottom={"20px"}>
                {localizedStrings.thankYouForPurchasing}
            </Text>
            <div>
                {
                    subscriptionType.value === "card" &&
                    <ButtonStyle color={"#1a2565"} border={"1px solid #1a2565"} background={"#fff"} onClick={() => {
                        window.open(reviewPath, "_self")
                    }}>
                        {localizedStrings.generateMyReceipt}
                    </ButtonStyle>
                }
                <ButtonStyle onClick={() => {

                    window.open(LOGIN_URL, "_self")
                }}>
                    {localizedStrings.accessConteleTeamFieldManager}
                </ButtonStyle>
            </div>
        </SuccessDescriptionStyle>
    );
}
