
import styled from 'styled-components';

const addressFieldsStyle = styled.div(props => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0px 0 30px 0 ",
    "& > div": {
        maxWidth: "500px",
        width: "100%",
    }
}))
const inputDivStyle = styled.div(props => ({
    display: "flex",
    justifyContent: props.justifyContent || "center",
    alignItems: props.alignItems || "flex-start",
    flexDirection: "column",
    padding: props.padding || "10px 15px",
    width: props.width || "100%",
    color: props.hasError && 'red',
    "& > input": {
        padding: "8px",
    },
    "& > select": {
        padding: "5px",
        cursor: "pointer",
    },
    "& > input, & > select": {
        margin: "5px 0",
        width: props.inputWidth || "100%",
        border: props.hasError ? "1px solid #dc3545" : "1px solid #CBD5DD",
        borderRadius: "2px",
        outline: "none",
        height: props.isMobile ? "38px" : "32px",
        background: "#fff",
        color: "#444444",
        fontWeight: "bold",
        fontSize: "15px",
        fontFamily: "Effra light, Roboto,sans-serif"
    },
    "& > label:last-child": {
        color: "#AAAAAA"
    },
    "& > label:first-child": {
        color: "#767676",
        fontSize: "15px",
        fontFamily: "Effra light, Roboto,sans-serif"
    },
    "& > button": {
        background: "#1A2564",
        color: "#fff",
        fontFamily: "Effra light, Roboto,sans-serif",
        borderRadius: "2px",
        marginBottom: "5px",
        fontSize: props.isMobile ? "13px" : "15px",
        fontWeight: "bold",
        outline: "none",
        display: "flex",
        padding: props.isMobile ? "9px 9px" : "9px 13px",
        justifyContent: "center",
        alignSelf: props.alignSelf || "stretch",
    },
    ...props
}))


export {
    inputDivStyle as InputDivStyle,
    addressFieldsStyle as AddressFieldsStyle,

}
