import React, { useState } from 'react';
import { PriceItem, PricesList, PlanButton, } from './style'
import { Text, PriceBlock, MonthlyPrice } from '../../../components';
import { localizedStrings } from '../../../constants/localizedStrings';
import { useSelector } from 'react-redux';
const width = window.innerWidth;

const isMobile = width < 860;
export default function HorizontalPriceList({ paymentSelected, prices, selectPlan, register, licenses, name, submitOnClick = false }) {
    const [removeTopRadius, setRemoveTopRadius] = useState(false)
    // (paymentSelected);
    const {
        company
    } = useSelector(state => state.users)
    return (
        <PricesList isMobile={isMobile} selected={paymentSelected.index} removeTopRadius={removeTopRadius}>
            {
                prices
                    ?.filter(price => paymentSelected?.value?.type === price?.value?.type)
                    ?.map((price, index, pricesVisible) => {
                        const isSelected = paymentSelected.index === index;
                        if ((pricesVisible?.length === 1) !== removeTopRadius) {
                            setRemoveTopRadius(!removeTopRadius);
                        }
                        return (
                            <PriceItem style={{width:"240px"}} selected={isSelected} key={index} removeTopRadius={removeTopRadius} 
                                onClick={(e) => {
                                    document.getElementById("plan-" + index).click();
                                }}>
                                <div style={{padding: price.discount ? "16px 20px" : "19px 20px"}} >
                                    <Text fontWeight={"700"} fontSize={"18px"} color={"#1A2564"}>{price?.name}</Text>
                                    <Text style={{marginTop:"6px"}} fontWeight={"500"} fontSize={"10px"} color={"#41BA40"}>{price.discount && `${price.discount} ${localizedStrings.OfDiscount}`}</Text>
                                    <Text style={{marginTop:"6px"}} fontWeight={"500"} fontSize={"10px"} color={"#6A6A84"}>{!price.discount && localizedStrings.noDiscount}</Text>
                                </div>
                                <PriceBlock
                                    {...price}
                                    currency={company.currency}
                                    licenses={licenses}
                                    selected={isSelected}
                                />
                                <PlanButton isMobile={isMobile} selected={paymentSelected.index} className="i-want-this-plan">
                                    <Text as={"label"} htmlFor={"plan-" + index} fontSize={"12px"} color={"#fff"}>
                                        {localizedStrings.iWantThisPlan}
                                    </Text>
                                    <input name={"plan"} ref={register({ name: name })}
                                        onClick={() => {
                                            if (licenses !== "0" && licenses !== "") selectPlan(price, index);
                                        }}
                                        defaultChecked={isSelected}
                                        type={submitOnClick ? "submit" : "radio"} id={"plan-" + index} />
                                </PlanButton>
                            </PriceItem>
                        )
                    })
            }

        </PricesList>
    );
}
