
import styled from 'styled-components';

const paymentMethodSelectorStyle = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > p": {
        textAlign: "center",
        fontSize: "20px"
    },
}
))

const paymentMethodButtonStyle = styled.div(props => ({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: "15px",
    "& > div:first-child": {
        borderRadius: "5px 0 0 5px",
        borderWidth: "1px"
    },
    "& > div:last-child": {
        borderRadius:  props.hasOnlyOnePaymentBtn ? "5px 5px 0 0" : "0 5px 5px 0",
        borderWidth: "1px"
    },
}))
const paymentButton = styled.div(props => ({
    width:"155.5px",
    padding: "10px 0 10px 0 ",
    background: props.selected ? "#fff" : "#EEEEEE",
    display: "flex",
    justifyContent: "center",
    border: "1px solid #CBD5D090",
    cursor: "pointer",
}))

export {
    paymentButton as PaymentButton,
    paymentMethodButtonStyle as PaymentMethodButtonStyle,
    paymentMethodSelectorStyle as PaymentMethodSelectorStyle,
}
