import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';

const apiUrl = 'https://ge-prd-web-api.contele.com.br';

const generateHubspotToken = async ({ userMail }) => {
    const url = `${apiUrl}/api/v1/hubspot/generate-user-token`;

    const bodyPayload = {
        email: userMail
    };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bodyPayload)
    };

    try {
        const response = await fetch(url, requestOptions);

        if(response.status === 200) {
            const responsePayload = await response.json();

            const { token: hubspotToken } = responsePayload;

            return hubspotToken;
        };
    } catch(err) {
        console.error(err);
    };
    
    return false;
};

const onConversationsApiReady = ({ userMail, hubspotToken }) => {
    window.hsConversationsSettings = {
        loadImmediately: false,
        identificationEmail: userMail || '',
        identificationToken: hubspotToken || ''
    };

    const status = window.HubSpotConversations.widget.status();

    if (status.loaded) {
        window.HubSpotConversations.clear({resetWidget:true});
    } else {
        window.HubSpotConversations.widget.load();
    }
};

const setTrackerVisitor = ({ userMail }) => {
    var _hsq = window._hsq = window._hsq || [];

    const visitor = {
        email: userMail
    };

    _hsq.push(["identify", visitor]);
};

const setVisitorIdentification = ({ userMail, hubspotToken }) => {
    if (window.HubSpotConversations) {
        onConversationsApiReady({ userMail, hubspotToken });
    } else {
        window.hsConversationsOnReady = [
            () => onConversationsApiReady({ userMail, hubspotToken })
        ];
    };
};

export default function Hubspot() {
    const {
        user: {
            token
        }
    } = useSelector(state => state.users)

    const initHubspot = async () => {
        try {
            const userData = token && JSON.parse(atob(token.split('.')[1]));

            const userMail = userData?.userMail;

            if(!!token){
                const hubspotToken = await generateHubspotToken({ userMail });

                setTrackerVisitor({ userMail });
    
                setVisitorIdentification({ userMail, hubspotToken });
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        initHubspot();
    }, [token]);

    return <></>
}
