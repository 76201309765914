
import LocalizedStrings from 'react-localization';

const localizedStrings = new LocalizedStrings({
    pt: {
        doubts : "Dúvidas?",
        message: "Visite a",
        message2: "Central de Ajuda",
        currency: "R$",
        country: "BR",
        phoneMask: "+55 (11) 11111-1111",
        rawPhoneMask: "99999999999",
        companyDocumentMask: "11.111.111/1111-11",
        zipcodeMask: "11111-111",
        rawDocumentMask: "99999999999999",
        rawZipCodeMask: "99999999",
        driverLicenseMask: "99999999999",
        dateTimeMask: "dd/MM/yyyy hh:mm",
        dateMask: "dd/MM/yyyy",
        cardNumberMask: "9999999999999999",
        securityCodeMask: "999",
        creditCard: "Cartão de crédito",
        licenses: "Usuários",
        registations: "Cadastro",
        confimation: "Confirmação",
        bankSlip: "Boleto bancário",
        monthlyCreditCard: "Cartão de crédito",
        iWantThisPlan: "Quero esse plano",
        paymentMethodQuestion: "Qual sua forma de pagamento?",
        billingData: "Dados de Cobrança",
        from: "A partir de",
        employees: "usuários",
        perEmployee: "por funcionário",
        enterTheNumberOfEmployees: "Digite a quantidade de usuários",
        thereIsNoDiscount: "Não possui desconto",
        nextChargeDate: "Data da Próxima Cobrança",
        invoiceAmount: "Valor da fatura",
        contractedLicenses: "Usuários Contratados",
        hireMoreLicenses: "Contratar mais Usuários",
        updateFormOfPayment: "Atualizar forma de pagamento",
        editBillingAddress: "Editar Endereço de Cobrança",
        date: "Data",
        payment: "Pagamento",
        price: "Preço",
        licencesQuantity: "Usuários",
        DiscountOf: "Desconto de",
        before: "Antes",
        OfDiscount: "de desconto",
        viewReceipt: "Ver recibo",
        pay: "Pagar",
        cashValuePerSemester: "Valor à vista por semestre",
        cashValuePerYear: "Valor à vista por ano",
        cashValuePerQuarter: "Valor à vista por trimestre",
        cashValueMonthly: "Valor à vista mensal",
        orderHasBeenPlaced: "Parabéns! Seu pedido foi efetuado.",
        orderHasBeenPlacedBankSlipTitle: "Você receberá no seu e-mail o boleto para pagamento da fatura.",
        orderHasBeenPlacedCreditCardTitle: "A sua nota fiscal será enviado para o e-mail cadastrado nos próximos instantes.",
        orderHasBeenPlacedDescription1: "Para mais informações sobre sua compra, acesse os",
        orderHasBeenPlacedDescription2: "Dados de Cobrança.",
        orderHasBeenPlacedDescription3: " Caso você possua mais dúvidas sobre sua compra, sugerimos o contato com nosso e-mail de suporte",
        orderHasBeenPlacedEmail: "suporte@contelege.com.br",
        thankYouForPurchasing: "Obrigado por adquirir nossos serviços!",
        accessConteleTeamFieldManager: "Acessar o Gestor de Equipes",
        goBackTo: "Voltar para",
        creditCardData: "Dados do cartão de crédito",
        creditCardName: "Nome impresso no cartão",
        creditCardNumber: "Número do cartão",
        creditCardSecurityCode: "Código de segurança (CVC)",
        privateDescription: "Suas informação estão seguras.",
        registerCard: "Cadastrar cartão",
        register: "Cadastrar",
        makeYourRegistrationBelow: "Faça seu cadastro abaixo!",
        documentNumber: "Número do documento",
        documentNumberPlaceholder: "Digite seu CNPJ",
        onlyNumbers: "Digite somente números",
        enterYourPhone: "Digite seu número de celular",
        enterYourPhonePlaceholder: "Digite seu telefone aqui",
        enterYourEmails: "E-mail de cópia",
        enterYourZipCode: "Digite seu CEP",
        enterYourZipCodePlaceholder: "Digite aqui seu CEP",
        enterYourZipCodeButton: "Buscar CEP",
        enterYourAddress: "Endereço",
        enterYourAddressPlaceholder: "Digite aqui seu endereço",
        enterYourAddressNumber: "Número",
        enterYourAddressComplement: "Complemento",
        enterYourAddressNeighborhood: "Bairro",
        enterYourAddressCity: "Cidade",
        enterYourAddressState: "Estado",
        subscriptionSummary: "Resumo de sua Assinatura",
        licensesNumber: "Numero de usuários",
        licensesPrice: "Valor por usuário",
        selectedPlan: "Plano selecionado",
        paymentMethod: "Método de pagamento",
        confirmPaymentDescription1: `O valor cobrado acima será referente a duração selecionada. Sua conta estará ativa assim que o pagamento for confirmado.
        Não realizamos reembolsos parciais. Ao confirmar sua assinatura, você está de acordo com os`,
        confirmPaymentDescription2: "Termos de Uso e Condições do Contele Gestor de Equipes.",
        confirmPaymentDescription3: "Os pagamentos serão processados automaticamente de maneira segura pela Vindi. Para saber mais sobre nosso gateway de pagamento,",
        confirmPaymentDescription4: "clique aqui.",
        generateTicket: "gerar boleto",
        startSubscription: "Iniciar assinatura",
        selectConsultant: "Selecione o Consultor que te atendeu",
        monthNamesShort: ['Jan.', 'Fev.', 'Mar.', 'Abr.', 'Maio.', 'Jun.', 'Jul.', 'Ago.', 'Set.', 'Out.', 'Nov.', 'Dez.'],
        dayNamesShort: ['Dom.', 'Seg.', 'Terç.', 'Qua.', 'Qui.', 'Sex.', 'Sáb.'],
        expirationDate: "Data de vencimento",
        quarterly: "Plano Trimestral",
        semester: "Plano Semestral",
        annual: "Plano Anual",
        monthly: "Plano Mensal",
        updateLicenses: "Atualizar usuários",
        close: "Fechar",
        errorCreatingSubscription: "Ocorreu um erro inesperado ao criar a sua assinatura.",
        errorUpdatingSubscription: "Ocorreu um erro inesperado ao atualizar a sua assinatura.",
        errorSubscription: "Erro na assinatura",
        errorDescriptionGeneric: "Confira todos os dados e tente novamente. Se o erro persistir entre em contato com nosso suporte.",
        errorDescription1: "Confira o seu ",
        errorDescription2: " e tente novamente. Se o erro persistir entre em contato com nosso suporte.",
        modalPaymentMethod: "Forma de pagamento",
        modalPaymentMethodQuestion: "Tem certeza que deseja mudar sua forma de pagamento?",
        answerYes: "Sim",
        answerNo: "Não",
        modalSuccessTitle: "Dados do cadastro",
        modalSuccessSubtitle: "Suas alterações foram feitas com sucesso",
        monthlyPrice: "Valor Mensal",
        generateMyReceipt: "Gerar meu recibo",
        errorIdentification: "cnpj",
        errorPhone: "número de telefone",
        errorCard: "número do cartão",
        errorHolderName: "nome impresso no cartão",
        errorLimitExceeded: "Você excedeu o limite de atualizações do método de pagamento, tente novamente mais tarde",
        errorPaymentCompanyCode: "cartão: Não aceitamos essa bandeira de cartão. Por favor, troque o cartão e tente novamente. Se o erro persistir, entre em contato com nosso suporte pelo Chat.",
        takingTooLong: "Demorando muito?",
        clickHereToReturnToSystem: "Clique aqui para voltar para o sistema",
        altPaymentMethod: "metodo de pagamento",
        altCurrentStep: "passo atual",
        altEdit: "editar",
        altLogo: "contele logo",
        altEnvelope: "envelope",
        altCardType: "bandeira do cartao",
        altGreenLock: "cadeado verde",
        altSuccess: "success",
        altImage: "imagem",
        emailsLimit: "Insira até 3 e-mail adicionais separando-os com virgula.",
        hireMoreLicensesChat: "Olá, gostaria de contratar mais licenças!",
        updateFormOfPaymentChat: "Olá, gostaria de atualizar minha forma de pagamento!",
        restrictAcess: "Acesso restrito ao Gestor",
        warningLock: "Somente contas com nível de Gestor podem acessar a página de contratação. Solicite ao seu Gestor para mudar o nível do seu acesso.",
        redirectMessage: "Em instantes, você será redirecionado de volta ao sistema.",
        understand: "Entendi",
        documentNumberError: "CNPJ inválido. Para continuar digite um CNPJ válido.",
        phoneError: "Número de celular não pode estar vazio.",
        zipCodeError: "CEP inválido. Para continuar digite um CEP válido.",
        zipCodeInvalid: "CEP não encontrado",
        zipCodeWrong: "CEP incorreto ou sem resultado.",
        zipCodeNotFoundMessage: "Por favor, revisar o CEP ou entrar em contato com o seu Consultor Especialista.",
        financialContact: "Em caso de dúvida, entre em contato pelo e-mail: financeiro@contele.com.br",
        cardNameError: "Nome impresso no cartão não pode estar vazio.",
        cardNumberError: "Número do cartão não pode estar vazio.",
        cardExpirationDateError:"Número de data de vencimento não pode estar vazio ou ser inválido",
        securityCodeError: "Código de segurança não pode estar vazio.",
        returnToSystem: "Voltar para o sistema",
        noDiscount: "Sem desconto",
        above:"Acima de",
        the: 'a',
        perUser:'/mês por Usuário',

    },
    en: {
        doubts: "Doubts?",
        message: "Visit",
        message2: "Help Center",
        currency: "U$",
        country: "US",
        phoneMask: "+01 (111) 111-1111",
        rawPhoneMask: "9999999999",
        companyDocumentMask: "111-11-1111",
        zipcodeMask: "11111",
        rawDocumentMask: "999999999",
        rawZipCodeMask: "99999",
        dateTimeMask: "MM/dd/yyyy hh:mm",
        dateMask: "MM/dd/yyyy",
        cardNumberMask: "9999999999999999",
        securityCodeMask: "999",
        creditCard: "Credit card",
        licenses: "Users",
        registations: "Registration",
        confimation: "Confirmation",
        bankSlip: "Bank slip",
        DiscountOf: "Discount of",
        monthlyCreditCard: "Credit card",
        iWantThisPlan: "I want this plan",
        thereIsNoDiscount: "There is no discount",
        OfDiscount: "of discount",
        before: "Before",
        noDiscount: "No discount",
        paymentMethodQuestion: "What is your payment method?",
        billingData: "Billing Data",
        from: "From",
        employees: "users",
        perEmployee: "per employee",
        enterTheNumberOfEmployees: "Enter the number of users",
        nextChargeDate: "Next Billing Date",
        invoiceAmount: "Invoice amount",
        contractedLicenses: "Contracted Users",
        hireMoreLicenses: "Hire more Users",
        updateFormOfPayment: "Update form of payment",
        editBillingAddress: "Edit Billing Address",
        date: "Date",
        payment: "Payment",
        price: "Price",
        licencesQuantity: "Number of Users",
        viewReceipt: "View receipt",
        pay: "Pay",
        orderHasBeenPlaced: "Congratulations! Your order has been placed.",
        orderHasBeenPlacedBankSlipTitle: "You will receive the invoice for payment in your email.",
        orderHasBeenPlacedCreditCardTitle: "Your invoice will be sent to the registered email in the next few moments.",
        orderHasBeenPlacedDescription1: "For more information about your purchase, access the",
        orderHasBeenPlacedDescription2: "Billing Details.",
        orderHasBeenPlacedDescription3: "If you have any further questions about your purchase, we suggest contacting our support email",
        orderHasBeenPlacedEmail: "suporte@contelege.com.br",
        thankYouForPurchasing: "Thank you for purchasing our services!",
        accessConteleTeamFieldManager: "Access Contele Team Field Manager",
        goBackTo: "Go back to",
        creditCardData: "Credit card details",
        creditCardName: "Name printed on card",
        creditCardNumber: "Card number",
        creditCardSecurityCode: "Security code",
        privateDescription: "Your information is safe.",
        registerCard: "Register card",
        register: "Register",
        makeYourRegistrationBelow: "Make your registration below!",
        documentNumber: "Document number",
        documentNumberPlaceholder: "Enter your Company Registry Number",
        onlyNumbers: "Enter numbers only",
        enterYourPhone: "Enter your phone",
        enterYourPhonePlaceholder: "Enter your phone number here",
        enterYourEmails: "Copy email",
        enterYourZipCode: "type your zip code",
        enterYourZipCodePlaceholder: "Enter your zip code here",
        enterYourZipCodeButton: "Search zip code",
        enterYourAddress: "Address",
        enterYourAddressPlaceholder: "Enter your address here",
        enterYourAddressNumber: "Number",
        enterYourAddressComplement: "Complement",
        enterYourAddressNeighborhood: "Neighborhood",
        enterYourAddressCity: "City",
        enterYourAddressState: "State",
        subscriptionSummary: "Summary of Your Subscription",
        licensesNumber: "Number of users",
        licensesPrice: "Price per user",
        selectedPlan: "Selected plan",
        paymentMethod: "Payment method",
        confirmPaymentDescription1: `The amount charged above will refer to the selected duration. Your account will be active once the payment is confirmed.
                We do not make partial refunds. By confirming your subscription, you agree to the`,
        confirmPaymentDescription2: "Terms of Use and Conditions for Contele Team Manager.",
        confirmPaymentDescription3: "Payments will be automatically processed securely by Vindi. To learn more about our payment gateway,",
        confirmPaymentDescription4: "click here.",
        generateTicket: "generate bank slip",
        startSubscription: "Start subscription",
        selectConsultant: "Select the Consultant that served you",
        monthNamesShort: ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May.', 'Jun.', 'Jul.', 'Aug.', 'Sept.', 'Oct.', 'Nov.', 'Dec.'],
        dayNamesShort: ['Sun.', 'Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat.'],
        expirationDate: "Due date",
        quarterly: "Quarterly Plan",
        semester: "Semester Plan",
        annual: "Annual Plan",
        monthly: "Monthly Plan",
        updateLicenses: "Update Users",
        close: "Close",
        errorCreatingSubscription: "An unexpected error occurred while creating your subscription.",
        errorUpdatingSubscription: "An unexpected error occurred while updating your subscription.",
        errorSubscription: "Signature error",
        errorDescriptionGeneric: "Check all the data and try again. If the error persists please contact our support.",
        errorDescription1: "Check your ",
        cashValuePerSemester: "Cash value per semester",
        cashValuePerYear: "Cash value per year",
        cashValuePerQuarter: "Cash value per quarter",
        cashValueMonthly: "Cash value per month",
        errorDescription2: " and try again. If the error persists please contact our support.",
        modalPaymentMethod: "Form of payment",
        modalPaymentMethodQuestion: "Are you sure you want to change your payment method?",
        answerYes: "Yes",
        answerNo: "No",
        modalSuccessTitle: "Registration data",
        modalSuccessSubtitle: "Your changes were successfully made",
        monthlyPrice: "Monthly price",
        generateMyReceipt: "Generate my receipt",
        errorIdentification: "registry code",
        errorPhone: "phone number",
        errorCard: "card number",
        errorHolderName: "name printed on card",
        errorLimitExceeded: "You have exceeded the payment method update limit, please try again later",
        takingTooLong: "Taking too long?",
        clickHereToReturnToSystem: "Click here to return to the system",
        altPaymentMethod: "payment method",
        altCurrentStep: "current step",
        altEdit: "edit",
        altLogo: "contele logo",
        altEnvelope: "envelope",
        altCardType: "card type",
        altGreenLock: "green lock",
        altSuccess: "success",
        altImage: "image",
        emailsLimit: "Insert up to 3 additional emails, separating them with commas.",
        hireMoreLicensesChat: "Hello, I would like to hire more licenses!",
        updateFormOfPaymentChat: "Hello, I would like to update my payment method!",
        editBillingAddressChat: "Hello, I would like to edit my billing address!",
        restrictAcess: "Restricted access to the Manager",
        warningLock: "Only accounts with Manager level can access the hiring page. Ask your Manager to change the level of your access.",
        redirectMessage: "In no time, you will be redirected back to the system.",
        understand: "Understand",
        documentNumberError: "Invalid document number. To continue enter a valid document number.",
        phoneError: "Mobile number cannot be empty.",
        zipCodeError: "Invalid zip code. To continue enter a valid zip code.",
        cardNameError: "Name printed on the card cannot be empty.",
        cardNumberError: "Card number cannot be empty.",
        cardExpirationDateError: "Expiration date number cannot be empty or invalid",
        securityCodeError: "Security code cannot be empty.",
        returnToSystem: "Return to the system",
        above:"Above",
        the: 'the',
        perUser:'/month per user',
    }
})
const [userLang] = (navigator.language || navigator.userLanguage).split("-");
export { localizedStrings, userLang };