export const validateCnpj = (cnpj = "") => {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '') return false;
    // eslint-disable-next-line
    if (cnpj.length != 14)
        return false;

    if (cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999")
        return false;

    let cnpjLength = cnpj.length - 2
    let numbers = cnpj.substring(0, cnpjLength);
    const digits = cnpj.substring(cnpjLength);
    let soma = 0;
    let pos = cnpjLength - 7;
    for (let i = cnpjLength; i >= 1; i--) {
        soma += numbers.charAt(cnpjLength - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    let result = soma % 11 < 2 ? 0 : 11 - soma % 11;
    // eslint-disable-next-line
    if (result != digits.charAt(0))
        return false;

    cnpjLength = cnpjLength + 1;
    numbers = cnpj.substring(0, cnpjLength);
    soma = 0;
    pos = cnpjLength - 7;
    for (let i = cnpjLength; i >= 1; i--) {
        soma += numbers.charAt(cnpjLength - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    result = soma % 11 < 2 ? 0 : 11 - soma % 11;
    // eslint-disable-next-line
    if (result != digits.charAt(1))
        return false;

    return true;
}