import React, { useEffect, useState } from 'react';
import { InfoSummary, ConsultantSelector } from '../../containers'
import { localizedStrings } from '../../constants/localizedStrings';
import { useSelector } from 'react-redux';

export default function ConfirmSubscription({ register, setValue, getValues, goToPreviousStep }) {
    const {
        prices
    } = useSelector(state => state.prices);
    const [selectedPlan, setSelectedPlan] = useState({})
    const goToLicenseStep = () => goToPreviousStep(localizedStrings.licenses);

    const editPlan = () => goToLicenseStep();

    const editPaymentMethod = () => goToLicenseStep();

    const editLicenses = () => goToLicenseStep();

    useEffect(() => {
        const { paymentSelected } = getValues();  
        
        const price_type = prices.filter(price => price?.value?.type === paymentSelected?.value?.type);

        const price_plan = price_type.find(plan => plan?.value?.plan === paymentSelected?.value?.plan) || {};

        setSelectedPlan(price_plan);
         // eslint-disable-next-line
    }, [prices])

    return (
        <>
            <InfoSummary
                getValues={getValues}
                setValue={setValue}
                register={register}
                selectedPlan={selectedPlan}
                editPlan={editPlan}
                editPaymentMethod={editPaymentMethod}
                editLicenses={editLicenses}
            />
            <ConsultantSelector
                setValue={setValue}
                register={register}
                selectedPlan={selectedPlan}
            />
        </>
    );
}
