import React, { useEffect, useState } from 'react';
import { UserFieldsStyle, InputDivStyle } from './style'
import { Text, Input, FieldBlock } from '../../../components'
import "react-datepicker/dist/react-datepicker.css";
import { localizedStrings, userLang } from '../../../constants/localizedStrings';
const width = window.innerWidth;
const isMobile = width < 860;
export default function CreditCardFields({ register, setValue, getValues, defaultValues, edittion = false }) {
    const onChangeInput = (name, val) => setValue(name, val);


    useEffect(() => {
        if (defaultValues?.expirationDate) {
            setValue("cardName", defaultValues?.cardName);
        }
         // eslint-disable-next-line
    }, [defaultValues]);

    return (
        <UserFieldsStyle>
            <div>
                <Text fontSize={"19px"} marginBottom={"20px"} >
                    {localizedStrings.creditCardData}
                </Text>
                <div className="row" >
                    <InputDivStyle className={"col-md-12 col-sm-12 col-12"} style={{ padding: "10px 15px" }} >
                        <Text as={"label"} htmlFor={"cardName"} fontSize={"15px"} fontFamily={"Effra light,Roboto,sans-serif"}>
                            {localizedStrings.creditCardName}
                        </Text>
                        <Input
                            id={"cardName"} name={"cardName"} type={"text"} inputMask={"text"}
                            autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" showMask={true}
                            ref={register({ name: "cardName" })} defaultValue={edittion ? defaultValues?.cardName : getValues()?.cardName}
                            onChange={(event) => {
                                event.persist();
                                onChangeInput("cardName", event?.target?.value ?? "");
                            }}
                        />
                        <Text as={"label"} id={'cardName-error'} display={'none'} color={"#f00f0070 !important"} fontSize={"13px !important"} fontFamily={"Effra light,Roboto,sans-serif"}> {localizedStrings.cardNameError} </Text>
                    </InputDivStyle>
                    <FieldBlock
                        text={localizedStrings.creditCardNumber} textOptions={{ as: "label", htmlFor: "cardNumber", fontFamily: "Effra light, Roboto,sans-serif", fontSize: "15px" }}
                        inputOptions={{ id: "cardNumber", name: "cardNumber", type: "number", inputMask: "credit", showMask: true }}
                        onChange={(e) => onChangeInput("cardNumber", e?.target?.value ?? "")} defaultClasses={"col-md-12 col-sm-12 col-12"}
                        inputName={"cardNumber"} isRequired={true}
                        register={register} getValues={getValues}
                        textErrorOptions={{ as: "label", id: "cardNumber-error", display: "none", color: "#f00f0070 !important", fontFamily: "Effra light, Roboto,sans-serif", fontSize: "13px !important" }}
                        textError={localizedStrings.cardNumberError}
                    />

                    <FieldBlock
                        text={localizedStrings.expirationDate} textOptions={{ as: "label", htmlFor: "expirationDate", fontFamily: "Effra light, Roboto,sans-serif", fontSize: "15px" }}
                        inputOptions={{ id: "expirationDate", name: "expirationDate", type: "number",inputMask: "expirationDate", showMask: true }}
                        defaultClasses={"col-md-6 col-sm-6 col-6"} 
                        onChange={(e) => onChangeInput("expirationDate", e?.target?.value ?? "")}
                        inputName={"expirationDate"} isRequired={true}
                        register={register} getValues={getValues}
                        textErrorOptions={{ as: "label", id: "expirationDate-error", display: "none", color: "#f00f0070 !important", fontFamily: "Effra light, Roboto,sans-serif", fontSize: "13px !important" }}
                        textError={localizedStrings.cardExpirationDateError}
                    />
                    <FieldBlock
                        text={localizedStrings.creditCardSecurityCode} textOptions={{ as: "label", htmlFor: "securityCode", fontSize: isMobile ? "13px" : "15px", fontFamily: "Effra light, Roboto,sans-serif" }}
                        inputOptions={{ id: "securityCode", name: "securityCode", type: "number", inputMask: "creditSecurityCode", showMask: true }}
                        onChange={(e) => onChangeInput("securityCode", e?.target?.value ?? "")} defaultClasses={"col-md-6 col-sm-6 col-6"}
                        inputName={"securityCode"} isRequired={true}
                        register={register} getValues={getValues}
                        textErrorOptions={{ as: "label", id: "securityCode-error", display: "none", color: "#f00f0070 !important", fontFamily: "Effra light, Roboto,sans-serif", fontSize: "13px !important" }}
                        textError={localizedStrings.securityCodeError}
                    />

                </div>

            </div>
        </UserFieldsStyle>
    );
}
