
import styled from 'styled-components';

const helpInfoDiv = styled.div(props => ({
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    "& > img": {
        width: "20px",
        margin: "auto 8px",
        opacity: "0.3",
        cursor: "pointer",
    },
    "& > p, & > a": {
        fontFamily: "Effra light, Roboto,sans-serif",
        fontWeight: "900",
        fontSize: "15px",
        color: "#adadad",
        textDecoration: "none",
    }
}))



export {
    helpInfoDiv as HelpInfoDiv,
}
