import React from 'react';
import { PaymentMethodButtonStyle, PaymentButton, } from './style'
import { Text } from '../../../components';
import { useSelector } from 'react-redux';

export default function HorizontalPaymentMethodSelector({ planTypes, paymentSelected, selectPaymentMethod }, ) {
    const {
        company
    } = useSelector(state => state.users);
    return (
        <PaymentMethodButtonStyle  hasOnlyOnePaymentBtn={company?.country !== "BR"}>
            {
                planTypes
                    ?.filter(planType => company?.country === "BR" || planType?.value === "card")
                    ?.map((planType, index) => {
                        const isSelected = paymentSelected?.value?.type === planType?.value;
                        return (
                            <PaymentButton
                                key={index}
                                selected={isSelected}
                                onClick={() => company?.country === "BR" && selectPaymentMethod(planType, index)}>
                                <Text
                                    fontSize="13px"
                                    fontFamily="Effra light, Roboto,sans-serif"
                                    color={isSelected ? "#1A2564" : "#767676"}>
                                    {planType.name}
                                </Text>
                            </PaymentButton>
                        )
                    })
            }
        </PaymentMethodButtonStyle>
    );
}