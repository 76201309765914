import React from 'react';
import Router from './Router'
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import { store } from './store/';
import { resetStyle } from './assets/global.js'
import { fonts } from './assets/fonts.js'
import { createGlobalStyle } from 'styled-components';
import { bootstrapStyle } from './assets/bootstrap.js'
import { APP_PATH } from './constants/environment';
import Hubspot from './containers/hubspot';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

const GlobalStyle = createGlobalStyle`
  ${resetStyle}
  ${bootstrapStyle}
  ${fonts}
  /* other styles */
`;

export default function App() {
	return (
		<BrowserRouter basename={APP_PATH + "/"}>
			<Provider store={store}>
				<GlobalStyle />
				<ToastContainer
					position="top-right"
					autoClose={5000}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					draggable
				/>
				<Router />
				<Hubspot/>
			</Provider>
		</BrowserRouter>
	);
}

