import React from 'react';
import { CreditCardFields, DescriptionAndRegisterCardBtn } from '../../containers';
import { localizedStrings } from '../../constants/localizedStrings';
import { changeBorderColor, clearInputsErrors } from '../../utils/inputs';
import { compareAsc } from 'date-fns';
export default function RegisterCreditCard({
    register, setValue, getValues, goToNextStep,
    defaultValues, edittion = false, isLoading = false,
}) {
    const parseField = (val = "") => val.replace(/[-()_ .]/g, "")
    const validField = (val = "", mask) => parseField(val).length === mask.length;
    const validExpitationDate = ( dateExpiration = null ) => {

        if(dateExpiration === null ) return;

        const [expirationMonth, expirationYear] = dateExpiration.split('/');

        if(isNaN(expirationMonth) || isNaN(expirationYear) ) return ;

        const amountFromMonths = 12 
        const minimumQuantityMonths = 1;

        const invalidMonthCard = [
            expirationMonth > amountFromMonths,
            expirationMonth < minimumQuantityMonths
        ].some(Boolean);
        

        if( invalidMonthCard ) return ;

        const isExpirationDateGreaterOrEqualToCurrenteDate = compareAsc(new Date(expirationYear, expirationMonth), new Date()) === 1;

        return isExpirationDateGreaterOrEqualToCurrenteDate;

    }

    const verifyFields = () => {
        const { cardNumber, cardName, securityCode, expirationDate } = getValues();
        
        let errors = [];
        const hasValidCardNumber = validField(cardNumber, localizedStrings.cardNumberMask);
        if (!hasValidCardNumber) {
            errors.push("cardNumber")
        } else {
            clearInputsErrors(["cardNumber"])
        }

        const hasValidCardName = parseField(cardName).length > 1;
        if (!hasValidCardName) {
            errors.push("cardName")
        } else {
            clearInputsErrors(["cardName"])
        }

        const hasValidSecurityCode = validField(securityCode, localizedStrings.securityCodeMask);
        if (!hasValidSecurityCode) {
            errors.push("securityCode")
        } else {
            clearInputsErrors(["securityCode"])
        }

        const hasExpirationDate = validExpitationDate(expirationDate);
        if(!hasExpirationDate) {
            errors.push("expirationDate");
        } else {
            clearInputsErrors(["expirationDate"]);
        }



        errors.map(id => changeBorderColor({ id }))

        if (hasValidCardNumber && hasValidCardName && hasValidSecurityCode && hasExpirationDate) return !isLoading && goToNextStep(1);

        return false;
    };
    return (
        <>
            <CreditCardFields
                register={register}
                setValue={setValue}
                getValues={getValues}
                defaultValues={defaultValues}
                edittion={edittion}
            />
            <DescriptionAndRegisterCardBtn
                isLoading={isLoading}
                edittion={edittion}
                goToNextStep={verifyFields}
            />

        </>
    );
}
