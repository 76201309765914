import React from 'react';
import { StepItemStyle } from './style';
import { Text } from '../../components';
import { localizedStrings } from '../../constants/localizedStrings';

export default function StepItem({ step, index, goToPreviousStep }) {

    return (
        step.visible ?
            <StepItemStyle
                as={step.selected ? "a" : "div"}
                onClick={() => {                    
                    step.selected && goToPreviousStep(localizedStrings[step.key])
                }}
                selected={step.selected}
                key={index}>
                <img alt={localizedStrings.altCurrentStep} src={
                    step.selected ?
                        "https://images.contelege.com.br/contratacao/item-selected.svg" :
                        "https://images.contelege.com.br/contratacao/item.svg"
                } />
                <Text>{step.name}</Text>
            </StepItemStyle> : null
    );
}
