import React, { memo, useEffect, useState } from 'react';
import { LoadingContainer, LoadingWrapper, MessageContainer } from './style'
import { LoadingSpin, Text } from '../../components/'
import { SYSTEM_URL } from '../../constants/environment';
function LoadingSpinner({ limit, title, description, ...options }) {
    const [showMessage, setShowMessage] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setShowMessage(true)
        }, limit)
    })
    return (
        <LoadingWrapper className={"row"} {...options}>
            <div className={"col-md-12 col-sm-12 col-12"}>
                <LoadingContainer padding={showMessage && "40px 0"}>
                    <LoadingSpin
                        show={true}
                        backColor={"#f2f2f2"}
                    />
                </LoadingContainer>
                {
                    showMessage &&
                    <MessageContainer>
                        <Text fontFamily={"effra, Roboto,sans-serif"} marginBottom={"10px"} fontSize={"19px"}>{title}</Text>
                        <Text as={"a"} href={SYSTEM_URL} color={"#757575"} fontFamily={"Effra light, Roboto,sans-serif"} fontSize={"18px"}>
                            {description}
                        </Text>
                    </MessageContainer>
                }
            </div>
        </LoadingWrapper>
    );
}
export default memo(LoadingSpinner)