import React from 'react';
import { SummaryTextRow, SummaryDiv, SummaryDivRow } from './style'
import { Text, EditButton } from '../../components'
const width = window.innerWidth;
const isMobile = width < 860;
export default function PaymentSummaryItem({ name, value, hasEdition, isTitle = false, onEdit, isSummaryItem }) {

    return (
        <SummaryDivRow className={"col-md-12 col-sm-12 col-12"} >
            <SummaryDiv >
                <SummaryTextRow isTitle={isTitle}>
                    <div >
                        <Text fontSize={isMobile ? "15px" : "17px"} color={isTitle ? "#1A2564" : "#767676"} fontFamily={"Effra light, Roboto,sans-serif"}>
                            {name}
                        </Text>
                    </div>
                    <div>
                        <Text fontSize={isMobile ? "15px" : "17px"} color={isTitle ? "#1A2564" : "#767676"} fontFamily={"effra, Roboto,sans-serif"}>
                            {value}
                        </Text>
                    </div>
                </SummaryTextRow>
            </SummaryDiv>

            {
                isSummaryItem &&
                <EditButton
                    type={"button"}
                    show={hasEdition}
                    onEdit={onEdit}
                />
            }

        </SummaryDivRow>
    );
}
