
import styled from 'styled-components';

const infoSummaryStyle = styled.div(props => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "40px 0 15px 0 ",
    "& > div": {
        maxWidth: props.maxWidth || "500px",
        width: "100%"
    }
}))

const iconStyle = styled.img(props => ({
    width: "16px",
    position: "absolute",
    right: "10px",
    bottom: "12px",
}))

export{
    iconStyle as IconStyle,
    infoSummaryStyle as InfoSummaryStyle,
}