import React from 'react';
import { RegisterCardBtnStyle, InputDivStyle } from './style'
import { Text, SubmitButton } from '../../../components'
import { localizedStrings } from '../../../constants/localizedStrings';
export default function DescriptionAndRegisterCardBtn({
    goToNextStep, edittion = false,
    isLoading = false,
}) {
    return (
        <RegisterCardBtnStyle>
            <div>
                <InputDivStyle justifyContent={"end"} className="col-md-12 col-sm-12 col-12"  >
                    <img alt={localizedStrings.altGreenLock} src={"https://images.contelege.com.br/contratacao/green-lock.svg"} />
                    <Text fontSize={"19px"} marginBottom={"20px"} >
                        {localizedStrings.privateDescription}
                    </Text>
                </InputDivStyle>
                <SubmitButton
                    width={"100%"}
                    btnContainerOptions={{ padding: "0px" }}
                    isLoading={isLoading}
                    title={localizedStrings.registerCard}
                    onClick={(e) => !isLoading && goToNextStep()}
                    type={"button"}
                />
            </div>
        </RegisterCardBtnStyle>
    );
}
