import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import {
    NewSubscription,
    ReviewSubscription,
    Authentication,
    DoneSubscription,
    EditSubscription,
} from "./pages/";

export default function Router() {
    return (
        <Switch>
            <Route exact path={"/new"} component={NewSubscription} />
            <Route exact path={"/review"} component={ReviewSubscription} />
            <Route exact path={"/review/edit"} component={EditSubscription} />
            <Route exact path={"/success"} component={DoneSubscription} />
            <Route exact path="/auth" component={Authentication} />
            <Redirect from="/" to={"/auth"} />
        </Switch>
    )
}

export { Router, }