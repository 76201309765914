
import styled from 'styled-components';

const returnStepStyle = styled.div(props => ({
    marginBottom: "30px",
    "& a": {
        fontSize: "15px",
        cursor: "pointer",
        color: "#4596EC",
        fontFamily: "Effra light, Roboto,sans-serif",
        fontWeight: "900"
    },
    "& a:hover": {
        color: "#4596EC"
    },
    ...props,
}))


export {
    returnStepStyle as ReturnStepStyle,
}
