
import styled from 'styled-components';


const paymentMethodSelectorStyle = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    marginTop: "40px",
    justifyContent: "center",
    alignItems: "center",
    "& > p": {
        textAlign: "center",
        fontSize: "20px"
    },
}))


export {
    paymentMethodSelectorStyle as PaymentMethodSelectorStyle,
}
