import React, { forwardRef } from 'react';
import {
    InputDefault
} from './style.js';
import InputMask from 'react-input-mask';
import { localizedStrings } from '../../constants/localizedStrings'
const Input = function ({ type, inputMask, maxLength, showMask = false, ...option }, ref) {
    
    const listOfMasks = ["phone", "cpf", "cnpj", "cep", "date", "datetime", "text", "credit", "creditDate", "creditSecurityCode", "expirationDate"];
    const regExpMask = {
        isRegExp: false,
        regExpId: "9"
    }
    const getMaskWithCustomLength = (array, maxLength, id = "9") => {
        array.length = maxLength || 10;
        return array.join(id);
    }
    const maskTypes = {
        phone: () =>  localizedStrings.phoneMask,
        cpf: () => "999.999.999-99",
        cnpj: () => "99.999.999/9999-99",
        date: () => "99/99/9999",
        datetime: () => "99/99/9999 99:99",
        cep: () => "99999-999",
        text: () => "",
        credit: () => "9999 9999 9999 9999",
        creditDate: () => "99/99",
        creditSecurityCode: () => "999",
        expirationDate: () => "99/9999",
        undefined: (maxLength = inputMask?.maxLength, regExp = inputMask?.regExp) => {
            regExpMask.isRegExp = true;
            regExpMask["regExp"] = regExp || /([0-9]|[A-Z]|[a-z]|@|.)/gi;
            regExpMask["mask"] = getMaskWithCustomLength([], maxLength);
            
            return regExpMask.mask;
        }
    }
    const selectedMask = maskTypes[listOfMasks.find(mask => inputMask === mask)]();
    
    return (
        <InputMask mask={selectedMask} maskChar={showMask ? "_" : ""}
            alwaysShowMask={showMask} formatChars={regExpMask.isRegExp ? { '9': regExpMask.regExp } : null}
            {...option}>
            {(inputProps) => {

                if (inputMask === "custom" && inputProps.hasOwnProperty("component")) return inputProps.component({ ...inputProps, ...option })

                return (
                    <InputDefault 
                        ref={ref}
                        maxLength={maxLength ? maxLength : null}
                        {...inputProps}
                        {...option}
                    />
                )
            }}
        </InputMask>
    );
}

const forwardInput = forwardRef(Input);
export default forwardInput;