import React, { useState, useEffect, useRef } from 'react';
import { toast } from "react-toastify";

import { AddressFieldsStyle, InputDivStyle } from './style'
import { Text, Input, SubmitButton, ModalElement } from '../../../components'
import { searchAddreessFromZipCode } from '../../../store/modules'
import { useDispatch, useSelector } from 'react-redux';
import { localizedStrings } from './../../../constants/localizedStrings';
import MaskedInput from 'react-maskedinput';

const width = window.innerWidth;
const isMobile = width < 860;
export default function AddressFields({
    register, setValue, getValues, isLoading = false,
    goToNextStep, parseField, edittion = false, isDisabled,
}, ref) {

    const inputTimeout = useRef(null);
    const {
        company,
    } = useSelector(state => state.users);
    const {
        address, addressSuccess, addressFail, states, addressLoading
    } = useSelector(state => state.subscription);
    
    const [validFields, setValidFields] = useState(false);

    const [loadingAddress, setLoadingAddress] = useState(false);

    const [visibleAddress, setVisibleAddress] = useState(edittion || false);
    const [searchedAddress, setSearchedAddress] = useState({});

    const dispatch = useDispatch();
    const onChangeInput = (name, val) => setValue(name, val);

    const searchAddreess = async (value) => {
        const { cep } = getValues();

        const parsedCep = parseField(cep)

        if (parsedCep.length === localizedStrings.rawZipCodeMask.length) {
            await dispatch(searchAddreessFromZipCode({ zipCode: parsedCep }))
        }
    }

    useEffect(() => {
        if (address.hasOwnProperty("address")) {
            const addressItens = Object.keys(address);
            addressItens.map(item => {
                setValue(item, address[item] ?? "");
                return item;
            });
            setSearchedAddress(address);

            if (addressSuccess) setVisibleAddress(true);
        }
        if (addressFail) setVisibleAddress(false);
        // eslint-disable-next-line
    }, [address])

    const onZipCodeChange = ({ value }) => {
        setLoadingAddress(true);
        onChangeInput("cep", value ?? "");
        inputTimeout != null && inputTimeout.current && clearTimeout(inputTimeout.current);
        inputTimeout.current = setTimeout(() => {
            setLoadingAddress(false);
            if (!visibleAddress) return;
            searchAddreess(value)
        }, 1000);
    }
    
    const checkIfFieldHasBeenFilled = ({data}) => {
        const required_fileds = ['cep', 'emails', 'document', 'phone'];
        const invalid_fields = Object.keys(data)?.reduce((acc, key) => {
            const invalid_field = (required_fileds.includes(key) && (!data[key] || data[key]?.length === 0)) || false;
            if(invalid_field) {
                const label_error = document.getElementById(`${key}-error`);
                if(label_error) label_error.style.display = 'block';
                acc.push(key);
            }
            return acc;
        },[]);
        
        if(invalid_fields.length > 0) toast.warning('Por favor, preencha os campos obrigatórios.');

        return invalid_fields.length === 0;
    }

    const cepNotFoundMessage = () => (
        <div>
            {localizedStrings.zipCodeWrong}
            <br/>
            {localizedStrings.zipCodeNotFoundMessage}
            <br/><br/>
            {localizedStrings.financialContact}
        </div>
    )

    return (
        <AddressFieldsStyle>
            <div>
                <div className={"row"}>
                    <ModalElement
                        show={addressFail}
                        title={localizedStrings.zipCodeInvalid}
                        subtitle={cepNotFoundMessage()}
                        showErrors
                        type={"error"}
                    />
                    <InputDivStyle className={(visibleAddress ? "col-md-12 col-sm-12 col-12" : "col-md-8 col-sm-8 col-8")} hasError={addressFail}>
                        <Text as={"label"} htmlFor={"cep"} fontSize={"15px"} fontFamily={"Effra light,Roboto,sans-serif"}>
                            {localizedStrings.enterYourZipCode}
                        </Text>
                        <MaskedInput mask={localizedStrings.zipcodeMask} ref={register({ name: "cep" })}
                            placeholder={localizedStrings.enterYourZipCodePlaceholder}
                            value={getValues()?.cep || company?.address?.cep || company?.address?.zipcode}
                            id={"cep"} name="cep" size="20"
                            onChange={(event) => {
                                event.persist();
                                onZipCodeChange(event?.target);
                            }} />
                        <Text as={"label"} id={'cep-error'} display={'none'} color={"#f00f0070 !important"} fontSize={"13px !important"} fontFamily={"Effra light,Roboto,sans-serif"}> {localizedStrings.zipCodeError} </Text>
                    </InputDivStyle>
                    {!visibleAddress &&
                        <InputDivStyle justifyContent="flex-end" padding={"10px 10px"} className="col-md-4 col-sm-4 col-4" isMobile={isMobile}>
                            <button type={"button"} onClick={(e) => !addressLoading && searchAddreess()}>
                                {localizedStrings.enterYourZipCodeButton}
                            </button>
                        </InputDivStyle>
                    }

                </div>
                <div className="row" style={!visibleAddress ? { position: "absolute", opacity: "0", top: "0px", left: "0px", zIndex: -1 } : {}}>
                    <InputDivStyle className={"col-md-8 col-sm-12 col-12"} >
                        <Text as={"label"} htmlFor={"address"} fontSize={"15px"} fontFamily={"Effra light,Roboto,sans-serif"}>
                            {localizedStrings.enterYourAddress}
                        </Text>
                        <Input
                            id={"address"} name={"address"} type={"text"} inputMask={"text"} readOnly
                            autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" showMask={true}
                            placeholder={localizedStrings.enterYourAddressPlaceholder}
                            ref={register({ name: "address" })} value={searchedAddress?.address || getValues()?.address}
                            onChange={(event) => {
                                event.persist();
                                onChangeInput("address", event?.target?.value ?? "");
                            }}
                        />
                    </InputDivStyle>
                    <InputDivStyle className={"col-md-4 col-sm-6 col-6"} >
                        <Text as={"label"} htmlFor={"addressNumber"} fontSize={"15px"} fontFamily={"Effra light,Roboto,sans-serif"}>
                            {localizedStrings.enterYourAddressNumber}
                        </Text>
                        <Input
                            id={"addressNumber"} name={"addressNumber"} type={"text"} inputMask={"text"}
                            autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" showMask={true}
                            ref={register({ name: "addressNumber" })} defaultValue={searchedAddress?.addressNumber || getValues()?.addressNumber}
                            onChange={(event) => {
                                event.persist();
                                onChangeInput("addressNumber", event?.target?.value ?? "");
                            }}
                        />
                    </InputDivStyle>
                    <InputDivStyle className={"col-md-6 col-sm-6 col-6"} >
                        <Text as={"label"} htmlFor={"complement"} fontSize={"15px"} fontFamily={"Effra light,Roboto,sans-serif"}>
                            {localizedStrings.enterYourAddressComplement}
                        </Text>
                        <Input
                            id={"complement"} name={"complement"} type={"text"} inputMask={"text"}
                            autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" showMask={true}
                            ref={register({ name: "complement" })} defaultValue={searchedAddress?.complement || getValues()?.complement}
                            onChange={(event) => {
                                event.persist();
                                onChangeInput("complement", event?.target?.value ?? "");
                            }}
                        />
                    </InputDivStyle>
                    <InputDivStyle className={"col-md-6 col-sm-6 col-6"} >
                        <Text as={"label"} htmlFor={"neighborhood"} fontSize={"15px"} fontFamily={"Effra light,Roboto,sans-serif"}>
                            {localizedStrings.enterYourAddressNeighborhood}
                        </Text>
                        <Input
                            id={"neighborhood"} name={"neighborhood"} type={"text"} inputMask={"text"} readOnly
                            autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" showMask={true}
                            ref={register({ name: "neighborhood" })} value={searchedAddress?.neighborhood || getValues()?.neighborhood}
                            onChange={(event) => {
                                event.persist();
                                onChangeInput("neighborhood", event?.target?.value ?? "");
                            }}
                        />
                    </InputDivStyle>
                    <InputDivStyle className={"col-md-6 col-sm-6 col-6"} >
                        <Text as={"label"} htmlFor={"city"} fontSize={"15px"} fontFamily={"Effra light,Roboto,sans-serif"}>
                            {localizedStrings.enterYourAddressCity}
                        </Text>
                        <Input
                            id={"city"} name={"city"} type={"text"} inputMask={"text"} readOnly
                            autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" showMask={true}
                            ref={register({ name: "city" })} value={searchedAddress?.city || getValues()?.city}
                            onChange={(event) => {
                                event.persist();
                                onChangeInput("city", event?.target?.value ?? "");
                            }}
                        />
                    </InputDivStyle>
                    <InputDivStyle className="col-md-6 col-sm-12 col-12" isMobile={isMobile}>
                        <Text as={"label"} htmlFor={"state"} fontSize={"19px"} >
                            {localizedStrings.enterYourAddressState}
                        </Text>
                        <select id="state"
                            ref={register({ name: "state", required: true })} readOnly
                            onSelect={() => false}
                            onChange={(e) => {
                                e.persist();
                                const [stateUf] = e.target.value?.split("- ");
                                setValue("state", stateUf);
                            }}>
                            <option value="00">
                                {localizedStrings.enterYourAddressState}
                            </option>
                            {
                                states.map((state, index) => <option
                                    value={state.minifiedName} key={index}
                                    selected={edittion
                                        ? getValues()?.state === state.minifiedName
                                        : state.selected
                                    }
                                >{state.name} - {state.minifiedName}</option>)
                            }
                        </select>
                    </InputDivStyle>
                    <SubmitButton
                        width={"100%"}
                        btnContainerOptions={{ padding: "0px" }}
                        isLoading={isLoading}
                        isDisabled={isDisabled || addressLoading || loadingAddress}
                        title={localizedStrings.register}
                        onClick={(e) => {
                            const proceed = checkIfFieldHasBeenFilled({ data: getValues() });
                            if(!isLoading && proceed) {
                                goToNextStep();
                            }
                        }}
                        type={"button"}
                    />
                </div>
            </div>
        </AddressFieldsStyle>
    );
}
