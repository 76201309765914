import React, { memo } from 'react';
import { FooterStyle } from './style'
import { Text } from '../../components'
export default memo(function Footer({ ...options }) {
    return (
        <div className={"row"}{...options}>
            <FooterStyle className="col-md-12 col-sm-12 col-12" >
                <Text>Copyright 2003-{new Date().getFullYear()} Contele Soluções Tecnológicas LTDA </Text>
                <Text>Rua Euclides da Cunha, n 11 - Cj 601 - Santos/SP</Text>
            </FooterStyle>
        </div>
    );
})